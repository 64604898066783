import * as api from '../../api';
import { FETCH_CONVERSION_RATE } from '../../constants/actionTypes';

export const getConversionRate = (start, end) => async (dispatch) => {
    try {
        const data = await api.fetchConversionRate(start, end);
        dispatch({type: FETCH_CONVERSION_RATE, payload: data});
    } catch (error) {
        console.log(error);
    }
}