import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import './styles.scss';

const dictionary={
    saved: {
        header: {
            success: {ru: 'Вы успешно сохранили все изменения', en: 'You have successfully saved all changes', kz: 'Барлық өзгертулер сәтті сақталды'},
            error: {ru: 'Ошибка', en: 'Error', kz: 'Қате'},
        },
        message: {
            success: {ru: 'Все изменения можете посмотреть на <a href="/" target="_blank">сайте</a>', en: 'All changes can be viewed on the <a href="/" target="_blank">website</a>', kz: 'Барлық өзгертулерді <a href="/" target="_blank">веб-сайттан</a> көруге болады'},
            error: {ru: 'К сожалению, изменения не могут быть сохранены. Проверьте интернет-соединение', en: 'Unfortunately, the changes cannot be saved. Check your internet connection', kz: 'Өкінішке орай, өзгертулерді сақтау мүмкін емес. Интернет байланысын тексеріңіз'},
        }
    },
    userAdded: {
        header: {
            success: {ru: 'Пользователь успешно добавлен', en: 'User added successfully', kz: 'Пайдаланушы қосылды'},
            error: {ru: 'К сожалению, мы не можем добавить пользователя', en: 'Unfortunately, we cannot add a user.', kz: 'Кешіріңіз, пайдаланушыны қосу мүмкін емес'},
        },
        message: {
            success: {ru: 'Пользователю отправлено письмо на e-mail с паролем и ссылкой на сайт администратора', en: `An e-mail was sent to the user with a password and a link to the administrator's site`, kz: 'Пайдаланушыға пароль және әкімшінің сайтына сілтемесі бар электрондық пошта жіберілді'},
            error: {ru: 'Не удалось найти пользователя с таким e-mail', en: 'Could not find a user with this e-mail', kz: 'Осы электрондық поштасы бар пайдаланушы табылмады'},
        }
    },
    deleteUser: {
        header: {ru: 'Вы действительно хотите удалить пользователя', en: 'Are you sure you want to delete the user', kz: 'Пайдаланушыны жойғыңыз келетініне сенімдісіз бе: '},
        message: {ru: 'Его учетная запись не будет действительна', en: 'His account will not be valid', kz: 'Оның есептік жазбасы жарамсыз болады'},
    },
    archive: {
        header: {ru: 'Хотите архивировать заявку', en: 'Do you want to archive your request?', kz: 'Өтінішіңізді мұрағаттағыңыз келе ме?'},
        message: {ru: 'Все архивированные заявки сохраняются в разделе "Архивированные"', en: 'All archived requests are saved in the "Archived" section', kz: 'Барлық мұрағатталған өтініштер «Мұрағатталған» бөлімінде сақталады'},
    },
    deleteRequest: {
        header: {ru: 'Хотите удалить заявку', en: 'Do you want to delete a request?', kz: 'Өтінішті жойғыңыз келе ме?'},
        message: {ru: 'Заявка будет удалена навсегда', en: 'Request will be deleted permanently', kz: 'Өтінім біржола жойылады'},
    },
    delete: {ru: 'Удалить', en: 'Delete', kz: 'Жою'},
    archiveBtn: {ru: 'Архивировать', en: 'Archive', kz: 'Мұрағат'},
    cancel: {ru: 'Отмена', en: 'Cancel', kz: 'Болдырмау'}
}

const Modal = ({type='', alertStatus='',alertType='', actionType='',show=true, setShow, userName = '', action}) => {
    const body = document.querySelector('body');
    const {lang} = useSelector((state)=>state.global);

    useEffect(()=>{
        if(show){
            body.style.overflowY = 'hidden';
        }else{
            body.style.overflowY = 'auto';
        }
    },[show]);
    const close = (e) => {
        e.preventDefault();
        setShow(false);
    }

    return (
        <div className={`modal ${show ? '' : 'hidden'}`}>
            {show && (
                <div className={`modal-body ${type}`}>
                    <div className="modal-content">
                        {type==='alert' && (
                            <div className={`modal-icon ${alertStatus}`}>
                                <i></i>
                            </div>
                        )}
                        <div className="modal-text">
                            <div className="modal-header">
                                {type==='alert' ? dictionary[alertType].header[alertStatus][lang] : `${dictionary[actionType].header[lang]} ${userName}?`}
                                <div className="modal-close-btn" onClick={close}></div>
                            </div>
                            <div className="modal-message" dangerouslySetInnerHTML={{__html:type==='alert' ? dictionary[alertType].message[alertStatus][lang] : dictionary[actionType].message[lang]}}>
                            </div>
                        </div>
                    </div>
                    {type==='action' && (
                        <div className="modal-footer">
                            {actionType==='archive' && ( <button onClick={action} className="button-48">{dictionary.archiveBtn[lang]}</button> )}
                            {(actionType==='deleteUser' || actionType==='deleteRequest') && ( <button onClick={action} className="button-48">{dictionary.delete[lang]}</button> )}
                            <button className="button-48 outline" onClick={close}>{dictionary.cancel[lang]}</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Modal;