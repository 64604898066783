import { CLEAR_LANG_STATUS, FETCH_LANGS, LANG, TOGGLE_LANGS, CHANGE_COMPANY } from "../constants/actionTypes";

// http://89.219.32.45:5000/
const globalReducers = (state = {
        link: process.env.REACT_APP_SERVER_DEV,lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru', 
        consultation_status: null, 
        company: localStorage.getItem('company') ? localStorage.getItem('company') : null}, 
        action) => {
    switch (action.type) {
        case LANG:
            localStorage.setItem('lang', action.lang);
            return {...state, lang: action.lang};
        case FETCH_LANGS:
            return {...state, langs: action.payload.data};
        case TOGGLE_LANGS:
            return {...state, langs: action.payload.data, langChangeStatus: action.payload.status};
        case CLEAR_LANG_STATUS:
            return {...state, langChangeStatus: false};
        case CHANGE_COMPANY:
            localStorage.setItem('company', action.company);
            return {...state, company: action.company};
        default:
            return state;
    }
};
export default globalReducers;