import * as api from '../api';
import { CLEAR_IMAGE_STATUSES, UPDATE_IMAGE, UPDATE_IMAGES } from '../constants/actionTypes';

export const updateImage = (id, image, setProgress) => async (dispatch) => {
    try {
        dispatch({type: CLEAR_IMAGE_STATUSES});
        const data = await api.updateImage(id, image, setProgress);
        dispatch({type: UPDATE_IMAGE, payload: {...data, imageKey: id}});
    } catch (error) {
        dispatch({type: UPDATE_IMAGE, payload: {status: 400, imageKey: id}});
    }
}

export const updateImages = (parentId, images, formData, setProgress) => async (dispatch) => {
    try {
        dispatch({type: CLEAR_IMAGE_STATUSES});
        const data = await api.updateImages(parentId, images, formData, setProgress);
        dispatch({type: UPDATE_IMAGES, payload: {...data, imagesKey: parentId}});
    } catch (error) {
        dispatch({type: UPDATE_IMAGE, payload: {status: 400, imagesKey: parentId}});
    }
}