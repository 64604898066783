import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateItems, updateStaff } from "../../../actions/page";
import Loading from "../../Loading/Loading";
import LoadingModal from "../../Modals/LoadingModal";
import Modal from "../../Modals/Modal";
import Edit from "../Edit/Edit";

import './styles.scss';

const Block = ({ items=[], title, index=0}) => {
    const [initItems, setInitItems] = useState(items);
    const [tmpItems, setTmpItems] = useState(items);
    const [changed, setChanged] = useState(false);
    
    const {newItems, itemsKey, itemsStatus} = useSelector((state)=>state.pages);

    const [btnLoading, setBtnLoading] = useState(false);

    const handleChange = (type, index, change, fieldname) => {
        switch (type) {
            case "text":
                setTmpItems(existingItems => {
                    return existingItems.map((item, j) => {
                      return j === index ? {...item, text: change, fieldname} : item
                    })
                  })
                setChanged(true);
                break;
            case "singleText":
                setTmpItems(existingItems => {
                    return existingItems.map((item, j) => {
                        return j === index ? {...item, singleText: change, fieldname} : item
                    })
                    })
                setChanged(true);
                break;
            default:
                break;
        }
    }   
    const dispatch = useDispatch();
    const saveChanges = (e) => {
        setBtnLoading(true);
        dispatch(updateItems(tmpItems, index))
    }
    const cancelChanges = (e) => {
        setTmpItems(initItems);
        setChanged(false);
    }

    const [show, setShow] = useState(false);
    const [modalStatus, setModalStatus] = useState('');

    useEffect(()=>{
        if(itemsStatus && itemsKey===index){
            setBtnLoading(false);
            setChanged(false);
            setShow(true);
            if(newItems){
                setInitItems(newItems);
                setTmpItems(newItems);
            }
            if(itemsStatus >= 200 && itemsStatus < 300)
                setModalStatus('success');
            else
                setModalStatus('error');

        }
    },[itemsKey]);


    return (
        <div className="block">
            {btnLoading && (
                <LoadingModal />
            )}
            <Modal type='alert' alertStatus={modalStatus} alertType='saved' show={show} setShow={setShow} />
            {title && (
                <div className="block-title">
                    {title}
                </div>
            )}
            <div className="block-content">
                {tmpItems && tmpItems.length>0 && tmpItems.map((item, key)=>(
                    <div className="subblock" key={key}>
                        <div className="block-subtitle">{item.subtitle}</div>
                        <Edit index={key} type={item.type} handleChange={handleChange} 
                            text={item.text ? item.text : null}
                            fieldname={item.fieldname ? item.fieldname : null}
                            limit={item.limit ? item.limit : 100} 
                            image={item.image ? item.image : null} 
                            images={item.images ? item.images : null} 
                            carousellSize={item.size ? item.size : 0}
                            singleText={item.singleText ? item.singleText : null}
                            label={item.label ? item.label : null}
                            className={item.className ? item.className : ''}
                        />
                    </div>   
                ))}
                <div className="block-footer">
                    <button className={`button-48 outline `} disabled={!changed} onClick={cancelChanges} >Сбросить изменения</button>
                    <button className={`button-48 `} disabled={!changed && !btnLoading} onClick={saveChanges}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
} ;

export default Block;