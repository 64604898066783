import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateImage, updateImages } from "../../../actions/image";
import Loading from "../../Loading/Loading";
import LoadingModal from "../../Modals/LoadingModal";
import Modal from "../../Modals/Modal";
import ImageEdit from "../Edit/ImageEdit";
import ProgressBar from "../ProgressBar/ProgressBar";

import './styles.scss';

const ImageBlock = ({type, image, images, size, title="",subtitle="", className="", actions=[], _id})=>{
    const {link}=useSelector((state)=>state.global);
    const {image:newImage, images: newImages, imagesKey,imagesStatus, imageKey,imageStatus} = useSelector((state)=>state.image);

    const [initImage, setInitImage] = useState(image ? {...image, src: `${link}images/${image.src}`} : null);
    const [tmpImage, setTmpImage] = useState(image ? {...image, src: `${link}images/${image.src}`} : null);
    const [file, setFile] = useState(null);

    const [initImages, setInitImages] = useState(images ? images.map((img)=> {return {...img, src: `${link}images/${img.src}`}}) : null);
    const [tmpImages, setTmpImages] = useState(images ? images.map((img)=> {return {...img, src: `${link}images/${img.src}`}}) : null);
    const [files, setFiles] = useState({});
    const [deletedImages, setDeletedImages] = useState([])
    
    const [changed, setChanged] = useState(false);
    const [show, setShow] = useState(false);
    const [modalStatus, setModalStatus] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const dispatch = useDispatch();

    const [progress, setProgress] = useState(0);

    const saveChanges = () => {
        setBtnLoading(true);
        switch (type) {
            case "subpageIcon":
            case "image":
                dispatch(updateImage(_id, file, setProgress));
                break;
            case "carousell":
                let fileArr = [];
                let indexArr = [];
                for (const key in files) {
                    if (Object.hasOwnProperty.call(files, key)) {
                        const element = files[key];
                        fileArr.push(element);
                        indexArr.push(key);
                    }
                }
                dispatch(updateImages(_id, fileArr, {indexArr, deleteArr: deletedImages}, setProgress));
                break;
            default:
                break;
        }
    }
    const cancelChanges = () => {
        setTmpImage({...initImage});
        setTmpImages([...initImages]);
        setDeletedImages([]);
        setFiles({});
        setFile(null);
        setChanged(false);
    }

    // image
    useEffect(()=>{
        if(imagesStatus && imagesKey && _id===imagesKey){
            setBtnLoading(false);
            setChanged(false);
            setShow(true);
            if(newImages){
                setFiles({});
                setDeletedImages([]);
                let arr = new Array(size);
                for (let index = 0; index<size; index++) {
                    arr[index] = {src: null};
                }
                for (let index = 0; index < newImages.length && index<size; index++) {
                    const img = newImages[index];
                    arr[index] = {...img, src: `${link}images/${img.src}`};
                }
                setTmpImages(arr);
                setTmpImages(arr);
            }
            if(imagesStatus >= 200 && imagesStatus < 300)
                setModalStatus('success');
            else    
                setModalStatus('error');
        }
        if(imageStatus && imageKey && _id === imageKey){
            setBtnLoading(false);
            setChanged(false);
            setShow(true);
            if(newImage){
                setFile(null);
                setTmpImage({...newImage, src: `${link}images/${newImage.src}`});
                setInitImage({...newImage, src: `${link}images/${newImage.src}`});
            }
            if(imageStatus >= 200 && imageStatus < 300)
                setModalStatus('success');
            else    
                setModalStatus('error');
        }
    },[imagesKey, imageKey])
    // images
    useEffect(()=>{
        if(images){
            let arr = new Array(size);
            for (let index = 0; index<size; index++) {
                arr[index] = {src: null};
            }
            for (let index = 0; index < images.length && index<size; index++) {
                const img = images[index];
                arr[index] = {...img, src: `${link}images/${img.src}`};
            }
            setInitImages(arr);
            setTmpImages(arr);
        }
    }, [images]);

    const handleChange = async (type, file, id, actionType) => {
        switch (actionType) {
            case 'upload':
                switch (type) {
                    case "image":
                        setFile(file);
                        setTmpImage({...tmpImage, src: URL.createObjectURL(file)});
                        setChanged(true);
                        break;
                    case "images":
                        let arr = [];
                        if(id){
                            arr = tmpImages.map((img)=>{
                                if(img._id && img._id === id){
                                    setFiles({...files, [id]: file});
                                    return {...img, src: URL.createObjectURL(file)};
                                }
                                return img;
                            })
                        }else{
                            let found = false;
                            for (let i = 0; i < tmpImages.length; i++) {
                                const img = tmpImages[i];
                                if(!found && img.src === null){
                                    setFiles({...files, [i]: file});
                                    arr.push({...img, src: URL.createObjectURL(file)});
                                    found = true;
                                }else{
                                    arr.push(img);
                                }
                            }
                        }
                        setTmpImages(arr);
                        setChanged(true);
                    default:
                        break;
                }
                break;
            case 'remove':
                switch (type) {
                    case "images":          
                        let tmpFiles = files;
                        let index = id;
                        if(tmpImages[id]._id){
                            setDeletedImages(oldArray => [...oldArray, tmpImages[id]._id]);
                        }
                        if(tmpImages[id]._id && tmpFiles.hasOwnProperty(tmpImages[id]._id)){
                            delete tmpFiles[tmpImages[id]._id]
                            for (let i = 0; i < tmpImages.length; i++) {
                                const img = tmpImages[i];
                                if(img._id && img._id === id){
                                    index = i;
                                    break;
                                }
                            }
                        }else if(tmpFiles.hasOwnProperty(id)){
                            delete tmpFiles[id];
                        }
                        let arr = [];
                        for (let i = 0; i<size; i++) {
                            arr[i] = {src: null};
                        }
                        let newFiles = {};
                        for (let i = 0, j = 0; i < tmpImages.length && i<size; i++, j++) {
                            const img = tmpImages[i];
                            arr[j] = img;
                            if(i == index){
                                arr[j] = {src: null};
                                j--;
                            }
                            if(img._id && tmpFiles.hasOwnProperty(img._id)){
                                newFiles[img._id] = tmpFiles[img._id];
                            }else if(i !== j && tmpFiles.hasOwnProperty(i)){
                                newFiles[j] = tmpFiles[i];
                            }
                        }
                        setFiles(newFiles);
                        setTmpImages(arr);
                        setChanged(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }   

    return (
        <div className="block">
            {btnLoading && (
                <LoadingModal/>
            )}
            <Modal type='alert' alertStatus={modalStatus} alertType='saved' show={show} setShow={setShow} />
            {title && (
                <div className="block-title">
                    {title}
                </div>
            )}
            <div className="block-content">
                <div className="subblock">
                    {subtitle && (
                        <div className="block-subtitle">{subtitle}</div>
                    )}
                    <ImageEdit  type={type} handleChange={handleChange}
                        image={tmpImage ? tmpImage : null}
                        images={tmpImages ? tmpImages : null}
                        className={className ? className : ''}
                        actions={actions}
                    />
                    {btnLoading && (
                        <div className="pt-2">
                            <ProgressBar value={progress} />
                        </div>
                    )} 
                </div>   
                <div className="block-footer">
                    <button className={`button-48 outline `} disabled={!changed} onClick={cancelChanges} >Сбросить изменения</button>
                    <button className={`button-48 `} disabled={!changed && !btnLoading} onClick={saveChanges}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );  
};

export default ImageBlock;