import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConversionRate } from '../../actions/rag_zakyat/conversion';
import moment from 'moment';
import './styles.scss';
const dictionary = {
    name: {ru: 'Имя', en: 'Name', kz: 'Аты-жөні'},
    email: {ru: 'Электронная почта', en: 'Email', kz: 'Электрондық пошта'},
    direction: {ru: 'Направление', en: 'Direction', kz: 'Бағыт'},
    service: {ru: 'Услуга', en: 'Service', kz: 'Қызмет'},
    lang: {ru: 'Язык', en: 'Language', kz: 'Тіл'},
    lastWeek: {ru: 'за неделю',kz: '',en: ''},
    lastMonth: {ru: 'за месяц',kz: '',en: ''},
    lastHalfYear: {ru: 'за полгода',kz: '',en: ''},
    lastYear: {ru: 'за год',kz: '',en: ''},
    requestCount: {ru: 'Всего заявок',kz: 'Өтініштердің жалпы саны:',en: 'Total request'},
    January: {ru: 'Январь',kz: 'Қаңтар',en: 'January'},
    February: {ru: 'Февраль',kz: 'Ақпан',en: 'February'},
    March: {ru: 'Март',kz: 'Наурыз',en: 'March'},
    April: {ru: 'Апрель',kz: 'Сәуір',en: 'April'},
    May: {ru: 'Май',kz: 'Мамыр',en: 'May'},
    June: {ru: 'Июнь',kz: 'Маусым',en: 'June'},
    July: {ru: 'Июль',kz: 'Щілде',en: 'July'},
    August: {ru: 'Август',kz: 'Тамыз',en: 'August'},
    September: {ru: 'Сентябрь',kz: 'Қыркүйек',en: 'September'},
    October: {ru: 'Октябрь',kz: 'Қазан',en: 'October'},
    November: {ru: 'Ноябрь',kz: 'Қараша',en: 'November'},
    December: {ru: 'Декабрь',kz: 'Желтоқсан',en: 'December'},
    active: {ru: 'Активные', kz: 'Актив', en: 'Active'},
    archive: {ru: 'Архивированные', kz: 'Мұрағатталған', en: 'Archived'},
    archiveBtn: {ru: 'Архивировать', kz: 'Мұрағат', en: 'Archive'},
    deleteBtn: {ru: 'Удалить', kz: 'Жою', en: 'Delete'},
    requests: {ru: 'Заявки', kz: 'Өтініштер', en: 'Requests'},
    download: {ru: 'Скачать', kz: 'Жүктеп алу', en: 'Download'},


    block: {ru: 'Блок', kz: 'Блок', en: 'Block'},
    button: {ru: 'Кнопка', kz: 'Түйме', en: 'Button'},
    conversion: {ru: 'Конверсия', kz: 'Конверсия', en: 'Conversion rate'},
    in_percentage: {ru: 'В проценте', kz: 'Пайызбен', en: 'In percentage'},
}
const today = new Date(new Date().setUTCHours(0,0,0,0));
const oneDay = (1000 * 60 * 60 * 24);
const rangeInitials = {start: new Date(today.valueOf() - (6 * oneDay)), end: today, group: 'lastWeek'};
const RagZakyatTable = () => {
    const {conversionRate, conversionButtons} = useSelector((state) => state.admin);
    const {lang} = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const [tableRange, setTableRange] = useState(rangeInitials);
    const [conversion, setConversion] = useState(null);

    useEffect(() => {
        dispatch(getConversionRate(tableRange.start, tableRange.end));
    }, []);

    useEffect(()=>{
        if(conversionButtons, conversionRate){
            const arr = conversionButtons.map((btn)=>{
                const total = conversionRate[btn.name];
                const percentage = Math.ceil((total/conversionRate.total)*100);
                var color = 'black';
                if(percentage > 60) color = 'green';
                else if(percentage > 25) color = 'yellow';
                return {...btn, total, percentage, color};
            });
            setConversion(arr);
        }
    }, [conversionRate, conversionButtons])

    const changeTableRange = (e) => {
        // e.preventDefault();
        const value = e.currentTarget.dataset.range;
        let range = rangeInitials;
        const tmpStart = new Date(today.valueOf());
        switch (value) {
            case 'lastMonth': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-1));
                range.group = 'lastMonth';
                break;
            case 'lastHalfYear': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-6));
                range.group = 'lastHalfYear';
                break;
            case 'lastYear': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-12));
                range.group = 'lastYear';
                break;
            default:
            case 'lastWeek':
                range.start = new Date(tmpStart.valueOf() - (6*oneDay));
                range.group = 'lastWeek';
                break;
        }
        // console.log(range);
        setTableRange({...tableRange, start: range.start, group: range.group});
    }

    return (
        <div className='table-container'>
            <h4 className='semibold-32-48 mb-2'>Конверсия</h4>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='regular-16-20'>
                    {dictionary.requestCount[lang]}: {conversionRate ? conversionRate.total : 0}
                </div>
                <div className='dropdown'>
                    <button className='dropbtn regular-16-20'>{
                        tableRange.start.getFullYear() === tableRange.end.getFullYear() ? 
                            `${dictionary[moment(tableRange.start).format('MMMM')][lang]} ${moment(tableRange.start).format('DD')}` 
                            : 
                            `${dictionary[moment(tableRange.start).format('MMMM')][lang]} ${moment(tableRange.start).format('DD, YYYY')}`
                    } - {dictionary[moment(tableRange.end).format('MMMM')][lang]} {moment(tableRange.end).format('DD, YYYY')} {dictionary[tableRange.group][lang]
                    }</button>
                    <ul className='dropcontent'>
                        <li onMouseDown={changeTableRange} data-range="lastWeek">
                            {dictionary.lastWeek[lang]}
                        </li>
                        <li onMouseDown={changeTableRange} data-range="lastMonth">
                            {dictionary.lastMonth[lang]}
                        </li>
                        <li onMouseDown={changeTableRange} data-range="lastHalfYear">
                            {dictionary.lastHalfYear[lang]}
                        </li>
                        <li onMouseDown={changeTableRange} data-range="lastYear">
                            {dictionary.lastYear[lang]}
                        </li>
                    </ul>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{dictionary.block[lang]}</th>
                        <th>{dictionary.button[lang]}</th>
                        <th>{dictionary.conversion[lang]}</th>
                        <th>{dictionary.in_percentage[lang]}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {conversion && conversion.length > 0 && conversion.map((el, key) => (
                        <tr key={key}>
                            <td>{key+1}</td>
                            <td>{el.block[lang]}</td>
                            <td>{el.button[lang]}</td>
                            <td>{el.total}</td>
                            <td>
                                <span className={el.color}>
                                    {el.percentage} %
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RagZakyatTable;