import React from "react";

import './styles.scss';

const Loading = ({size=null, color=null}) => {
    return (
        <div className={`loading ${size ? size : ''} ${color ? color : ''}`}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;