import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageByName } from "../../../actions/page";
import Loading from "../../Loading/Loading";
import Block from "../../subcomponents/Block/Block";
import ImageBlock from "../../subcomponents/Block/ImageBlock";

const Home = () => {
    const {page} = useSelector((state)=>state.pages);
    const {lang} = useSelector((state)=>state.global);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!page){
            dispatch(getPageByName('home'));
        }
    },[dispatch, page]);
    
    return !page ? (
        <Loading />
    ) : (
        <div id="home">
            <div className="blocks">
                <ImageBlock 
                    title="Блок 1 - Стартовый блок" 
                    subtitle="Фоновая картинка"
                    type="image"
                    image={page.images[0]}
                    _id={page.images[0]._id}
                    actions={["upload"]}
                />
                <Block 
                    index={1}
                    items={[
                    {subtitle: "Заголовок блока", type:"text", text: page.home_heading, _id: page.home_heading._id},
                    {subtitle: "Текст блока", type:"text", text: page.home_subheading, _id: page.home_subheading._id, limit: 500}
                ]} />
                <Block items={[
                    {subtitle: "Заголовок блока", type:"text", text: page.home_directions_heading, _id: page.home_directions_heading._id},
                    {subtitle: "Текст блока", type:"text", text: page.home_directions_subheading, _id: page.home_directions_subheading._id, limit: 500}
                ]} title="Блок 2 - Направления" />
                <Block items={[
                    {subtitle: "Заголовок блока", type:"text", text: page.home_services_heading, _id: page.home_services_heading._id},
                    {subtitle: "Текст блока", type:"text", text: page.home_services_subheading, _id: page.home_services_subheading._id, limit: 500}
                ]} title="Блок 3 - Услуги" />
                <Block items={[
                    {subtitle: "Заголовок блока", type:"text", text: page.home_consulting_title, _id: page.home_consulting_title._id},
                    {subtitle: "Подзаголовок блока", type:"text", text: page.home_consulting_heading, _id: page.home_consulting_heading._id},
                    {subtitle: "Текст блока", type:"text", text: page.home_consulting_subheading, _id: page.home_consulting_subheading._id, limit: 500}
                ]} title="Блок 4 - Консультация" />
            </div>
        </div>
    );
};

export default Home;