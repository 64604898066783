import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getPages } from "../../actions/page";

import "./styles.scss";

const menu = {
    main: {ru: 'Основное', en: 'General', kz: 'Негізгі'},
    home: {ru: 'Главная страница', en: 'Main page', kz: 'Басты бет'},
    directions: {ru: 'Направления', en: 'Directions', kz: 'Бағыттар'},
    services: {ru: 'Услуги', en: 'Services', kz: 'Қызметтер'},
    about: {ru: 'О нас', en: 'About us', kz: 'Біз туралы'},
    contacts: {ru: 'Контакты и языки', en: 'Contacts and languages', kz: 'Байланыстар мен тілдер'},
    logout: {ru: 'Выйти', en: 'Logout', kz: 'Шығу'}
};

const RagMirsotMenu = () => {
    const dispatch = useDispatch();
    const {pages} = useSelector((state) => state.pages);
    const {lang} = useSelector((state) => state.global);

    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('profile'))?.user;

    useEffect(()=>{
        if(!pages){
            dispatch(getPages());
        }
    }, [pages]);

    const location = useLocation();

    
    const logout = (e) =>{
        e.preventDefault();
        localStorage.clear('profile');
        navigate('/login')
    }
    return (
        <div id="adminmenu">
            <ul className="subtitles">
                {user && user.role.name !== 'editor' && (
                    <li>
                        <a href="/" className={location.pathname === '/' ? 'active' : ''}>
                            {menu.main[lang]}
                        </a>
                    </li>
                )}
                {user && user.role.name !== 'analyst' && (
                    <>
                        <li>
                            <a href="/home" className={location.pathname === '/home' ? 'active' : ''}>
                                {menu.home[lang]}
                            </a>
                        </li> 
                        <li>
                            <a href="/directions" className={location.pathname === '/directions' ? 'active' : ''}>
                                {menu.directions[lang]}
                            </a>
                        </li> 
                        <li>
                            <a href="/services" className={location.pathname === '/services' ? 'active' : ''}>
                                {menu.services[lang]}
                            </a>
                        </li> 
                        <li>
                            <a href="/about" className={location.pathname === '/about' ? 'active' : ''}>
                                {menu.about[lang]}
                            </a>
                        </li>
                        <li>
                            <a href="/contacts" className={location.pathname === '/contacts' ? 'active' : ''}>  
                                {menu.contacts[lang]}
                            </a>
                        </li>
                    </>
                )}
                <li onClick={logout}>{menu.logout[lang]}</li>
            </ul>
        </div>
    );
};

export default RagMirsotMenu;