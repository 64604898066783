import { CLEAR_IMAGE_STATUSES, UPDATE_IMAGE, UPDATE_IMAGES, UPLOAD_PROGRESS } from "../constants/actionTypes";

const imageReducers = (state = {image: null, images: null, imagesKey: null}, action) => {
    switch (action.type) {
        case UPDATE_IMAGE:
            return {...state, image: action.payload.data, imageKey: action.payload.imageKey, imageStatus: action.payload.status};  
        case UPDATE_IMAGES: 
            return {...state, images: action.payload.data, imagesKey: action.payload.imagesKey, imagesStatus: action.payload.status};  
        case CLEAR_IMAGE_STATUSES:
            return {...state, imageStatus: null, imagesStatus: null};
        default:
            return state;
    }
};
export default imageReducers;