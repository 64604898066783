import { CLEAR_PAGE, CLEAR_PAGE_STATUSES, FETCH_PAGE, FETCH_PAGES, FETCH_PAGES_BY_PARENT, FETCH_TEXTS, UPDATE_ITEMS, UPDATE_STAFF, UPDATE_TEXT } from "../constants/actionTypes";

const userReducers = (state = {page: null,itemsKey:null}, action) => {
    switch (action.type) {
        case CLEAR_PAGE:
            return {...state, page: null};
        case FETCH_PAGES:
            return {...state, pages: action.payload.data};
        case FETCH_PAGES_BY_PARENT:
            return {...state, subpages: action.payload.data};
        case FETCH_PAGE:
            return {...state, page: action.payload.data};
        case FETCH_TEXTS:
            return {...state, texts: action.payload.data};
        case UPDATE_TEXT:
            return {...state, text: action.payload.data};
        case UPDATE_ITEMS:
            return {...state, newItems: action.payload.data, itemsKey: action.payload.itemsKey, itemsStatus: action.payload.status};
        case CLEAR_PAGE_STATUSES:
            return {...state, itemsStatus: null, itemsKey: null};
        // case UPDATE_STAFF:
        //     return {...state, }
            default:
            return state;
    }
};
export default userReducers;