import { ARCHIVE_REQUEST, CLEAR_ADMIN_STATUSES, CLEAR_CONSULTATION_REQUESTS, DELETE_REQUEST, DOWNLOAD_CONSULTATION_REQUESTS, FETCH_CONSULTATION_ANALYTICS, FETCH_CONSULTATION_REQUESTS, FETCH_CONSULTATION_YEARS, FETCH_CONVERSION_RATE, FETCH_VISITORS } from "../constants/actionTypes";

const adminReducers = (state = {
    visitors: [], 
    consultations: null, 
    consultationYears: null,
    archiveDownloadStatus:null, 
    consultationsAnalytics: [], 
    conversionButtons: [],
    conversionRate: {},
    total: null, limit: 15, count: 0}, action
    ) => {
    switch (action.type) {
        case FETCH_VISITORS:
            return {...state, visitors: action.payload.data};
        case FETCH_CONSULTATION_REQUESTS:
            return {...state, consultations: action.payload.data.consultations, page: action.payload.data.page, total: action.payload.data.total, limit: action.payload.data.limit, count: action.payload.data.count};
        case FETCH_CONSULTATION_ANALYTICS:
            return {...state, consultationAnalytics: action.payload.data};
        case FETCH_CONSULTATION_YEARS:
            return {...state, consultationYears: action.payload.data, consultations: []};
        case CLEAR_CONSULTATION_REQUESTS: 
            return {...state, consultations: null};
        case DELETE_REQUEST:
            return {...state, reqDeleteStatus: action.payload.status};
        case ARCHIVE_REQUEST:
            return {...state, reqArchiveStatus: action.payload.status};
        case DOWNLOAD_CONSULTATION_REQUESTS:
            return {...state, archiveDownloadStatus: action.payload.status};
        case CLEAR_ADMIN_STATUSES:
            return {...state, archiveDownloadStatus: null, reqArchiveStatus: null, reqDeleteStatus: null};
        // RAG_ZAKYAT
        
        case FETCH_CONVERSION_RATE:
            return {...state, conversionButtons: action.payload.data.buttons, conversionRate: action.payload.data.conversion};
        default:
            return state;
    }
};
export default adminReducers;