import React from "react";
import { useSelector } from "react-redux";
import RagMirsotDashboard from "./RagMirsotDashboard";
import RagZakyatDashboard from "./RagZakyatDashboard";

const Dashboard = () => {
    const {company} = useSelector((state) => state.global);
    switch(company){
        case 'rag-mirsot':
            return <RagMirsotDashboard />
        case 'rag-zakyat':
            return <RagZakyatDashboard />
        default:
            return null;
    }
};

export default Dashboard;