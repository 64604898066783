import * as api from '../api';
import { CLEAR_PAGE, CLEAR_PAGE_STATUSES, END_LOADING, FETCH_PAGE, FETCH_PAGES, FETCH_PAGES_BY_PARENT, FETCH_TEXTS, START_LOADING, UPDATE_ITEMS, UPDATE_TEXT } from '../constants/actionTypes';

export const getPageByName = (name, editable = false) => async(dispatch) => {
    try {
        dispatch({type: CLEAR_PAGE});
        dispatch({type: START_LOADING});
        const data = await api.fetchPageByName(name);
        dispatch({type: FETCH_PAGE, payload: data})
        dispatch({type: END_LOADING});
    } catch (error) {
        console.log(error);
    }
}

export const getPagesByParent = (name) => async(dispatch) => {
    try {
        const data = await api.fetchPagesByParent(name);
        dispatch({type: FETCH_PAGES_BY_PARENT, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const getPages = () => async(dispatch) => {
    try {
        const data = await api.fetchPages();
        dispatch({type: FETCH_PAGES, payload: data});
    } catch (error) {
        console.log(error);
    }
}
export const getTexts = (names) => async(dispatch) => {
    try {
        const data = await api.fetchTexts(names.join(','));
        dispatch({type: FETCH_TEXTS, payload: data});
    } catch (error) {
        console.log(error);
    }
}
export const updateText = (formData) => async(dispatch)=>{
    try {
        await dispatch({type: CLEAR_PAGE_STATUSES});
        const data = await api.updateText(formData);
        dispatch({type: UPDATE_TEXT, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const updateItems = (items, key) => async(dispatch)=> {
    try {
        await dispatch({type: CLEAR_PAGE_STATUSES});
        const data = await api.updateItems(items);
        dispatch({type: UPDATE_ITEMS, payload: {...data, itemsKey: key}});
    } catch (error) {
        dispatch({type: UPDATE_ITEMS, payload: {status: 400, itemsKey: key}});
    }
}
export const updateStaff = (id, image, formData, index) => async(dispatch)=> {
    try {
        dispatch({type: CLEAR_PAGE_STATUSES});
        const data = await api.updateStaff(id, image, formData);
        dispatch({type: UPDATE_ITEMS, payload: {...data, itemsKey: index}});
    } catch (error) {
        console.log(error);
    }
}