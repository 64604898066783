import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LANG } from "../../constants/actionTypes";

import './styles.scss';

const dictionary = {
    personalCabinet:{ru:'Личный кабинет', en:'Personal cabinet',kz:'Жеке кабинет'}
}

const RagMirsotHeader = () => {
    const {lang} = useSelector((state)=>state.global);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!lang)
            dispatch({type: LANG, lang: 'ru'});
    }, [lang]);
    const changeLanguage = (e) => {
        e.preventDefault();
        dispatch({type: LANG, lang: e.currentTarget.dataset.lang});
    };
    return (
        <div id="adminheader" className="rag-mirsot">
            <a className="brand" href="/">
                <i className="logotype"></i>
            </a>
            <div className="buttons">
                {/* <a className="button-48px show-prototype">Показать прототип</a> */}
                <a className="button-48px cabinet" href="/cabinet">{dictionary.personalCabinet[lang]}</a>
                <div id="language" className="dropdown">
                    <a className="dropbtn">
                        {((!lang || lang==='ru') && (<>
                            RU
                        </>)) || (lang==='en' && (<>
                            EN
                        </>)) || (lang==='kz' && (<>
                            ҚАЗ
                        </>))}
                    </a>
                    <ul className="dropcontent">
                        <li data-lang="en" className={lang === 'en' ? 'hidden' : ''} onClick={changeLanguage}>
                            <a>
                                <i data-lang="en" className="lang-en"></i>EN
                            </a>
                        </li>
                        <li data-lang="ru" className={(!lang || lang === 'ru') ? 'hidden' : ''} onClick={changeLanguage}>
                            <a>
                                <i data-lang="ru" className="lang-ru"></i>RU
                            </a>
                        </li>
                        <li data-lang="kz" className={lang === 'kz' ? 'hidden' : ''} onClick={changeLanguage}>
                            <a>
                                <i data-lang="kz" className="lang-kz"></i>ҚАЗ
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RagMirsotHeader;