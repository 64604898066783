import React from "react";
import { useSelector } from "react-redux";
import RagMirsotTable from "./RagMirsotTable";
import RagZakyatTable from "./RagZakyatTable";

const Table = () => {
    const {company} = useSelector((state)=>state.global);
    switch (company) {
        case 'rag-mirsot':
            return <RagMirsotTable />
        case 'rag-zakyat':
            return <RagZakyatTable />
        default:
            return null;
    }
};

export default Table;