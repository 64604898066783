import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import './styles.scss';

const ImageEdit = ({type=null, image, images, className="", handleChange,actions=[]}) => {
   
    const uploadImage = (e) =>{
        const file = e.currentTarget.files.length>0 ? e.currentTarget.files[0] : null;
        if(file){
            handleChange("image", file, null, 'upload'); 
        }
    }
    useEffect(()=>{
        if(image){
            clearValue(image._id, image.src);
        }
        if(images){
            for (let i = 0; i < images.length; i++) {
                const img = images[i];
                if(img._id)
                    clearValue(img._id, img.src);
                else
                    clearValue(i, img.src);
            }
        }
    }, [image, images]);


    const clearValue = (name, src) => {
        var input = document.querySelector(`input[name='${name}']`);
        if(input){
            const file = input.files.length>0 ? input.files[0] : null;
            if(file && URL.createObjectURL(file) !== src){
                input.value = null;
            }
        }
    }

    const uploadCarousellImage = (e) =>{
        const file = e.currentTarget.files.length>0 ? e.currentTarget.files[0] : null;
        const id = e.currentTarget.dataset.id;
        if(file){
            handleChange("images", file, id, 'upload'); 
        }
    }
    const removeCarouselImage = (e) =>{
        const index = e.currentTarget.dataset.index;
        handleChange("images", null, index, 'remove')
    }

    switch (type) {
        case "image":
            return (
                <div className="image-edit">
                    <div className={`img-container ${className}`}>
                        {image && (
                            <img src={image.src} /> 
                        )}
                        <div className="img-overlay">
                            <div className="actions">
                                {actions.map((action, key)=>(
                                    <label className={`action-btn ${action}`} key={key}>
                                        {action === 'upload' && (
                                            <input type='file' name={image ? image._id : 'img'} accept="image/png, image/jpeg, image/svg+xml" onChange={uploadImage}/>
                                        )}
                                        <i></i>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case "carousell":
            return (
                <div className="carousell">
                    {images && images.length>0 && images.map((img, key)=>(
                        <div className="carousell-item" key={key}>
                            <div className="image-edit">
                                <div className="img-container">
                                    {img.src ? (
                                        <>
                                            <img src={img.src} /> 
                                            <div className="img-overlay">
                                                <div className="actions">
                                                    <label className="action-btn upload">
                                                        <input type='file' name={img ? img._id : key} accept="image/png, image/jpeg, image/svg+xml" data-id={img._id ? img._id : null} onChange={uploadCarousellImage}/>
                                                        <i></i>
                                                    </label>
                                                    <div className="action-btn delete" data-index={key} onClick={removeCarouselImage}>
                                                        <i></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="noimg">
                                            <div className="actions">
                                                <label className="action-btn upload">
                                                    <input type='file' name={key} accept="image/png, image/jpeg, image/svg+xml" data-id={null} onChange={uploadCarousellImage}/>
                                                    <i></i>
                                                    <span>Добавить картинку</span>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        default:
            return (<></>)
    }
};

export default ImageEdit;