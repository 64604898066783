import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import decode from 'jwt-decode';

import { login } from '../../actions/user';

import './styles.scss';
import { CHANGE_COMPANY } from '../../constants/actionTypes';

const dictionary = {
    invalidCredentials:{
        ru: 'Неправильный логин или пароль.\nПопробуйте еще раз.', 
        kz: 'Қате логин немесе пароль.\nТағы да қайталап көріңіз.',
        en: 'Incorrect login or password.\nTry again.'
    },
    somethingWentWrong:{
        ru: 'Что-то пошло не так.\nПовторите попытку позже.', 
        kz: 'Бірдеңе дұрыс болмады.\nӘрекетті кейінірек қайталаңыз.',
        en: 'Something went wrong.\nTry again later.'
    },
    login:{
        ru: 'Вход',
        kz: 'Кіру',
        en: 'Login'
    },
    loginBtn:{
        ru: 'Войти',
        kz: 'Кіру',
        en: 'Login'
    },
    email:{
        ru: 'Почта',
        kz: 'Пошта',
        en: 'Email'
    },
    enterCorporateEmail: {
        ru:'Введите корпоративную почту',
        kz:'Корпоративтік поштаңы енгізіңіз',
        en:'Enter corporate email'
    },
    password:{
        ru: 'Пароль',
        kz: 'Құпия сөз',
        en: 'Password'
    },
    enterPassword:{
        ru: 'Введите пароль',
        kz: 'Құпия сөзді еңгізіңіз',
        en: 'Enter password'
    },
    rememberMe:{
        ru: 'запомнить меня',
        kz: 'мені есте сақтау',
        en: 'remember me'
    },
    forgotPassword:{
        ru: 'Забыли пароль?',
        kz: 'Құпия сөзді ұмытып қалдыңыз ба?',
        en: 'Forgot password?'
    },    
}

const Login = () => {
    const [email, setEmail] = useState({value: '', valid: false, error: ''});
    const [password, setPassword] = useState({value: '', valid: false, error: ''});
    const [remember, setRemember] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [error, setError] = useState('');

    const {lang} = useSelector((state)=>state.global);
    const {authStatus} = useSelector((state)=>state.users);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        if(authStatus){
            switch (authStatus) {
                case 200:
                    var pf = JSON.parse(localStorage.getItem('profile'));
                    if(pf && pf.user && pf.user.companies && pf.user.companies.length>0){
                        if(pf.user.companies.length == 1){
                            dispatch({type: CHANGE_COMPANY, company: pf.user.companies[0].name});
                            navigate('/cabinet');
                        }else{
                            navigate('/login/choose')
                        }
                    }else{
                        setError('somethingWentWrong')
                    }
                    break;
                case 404:
                case 401:
                    setError('invalidCredentials')
                    break;
                default:
                    setError('somethingWentWrong')
                    break;
            }
        }
    }, [authStatus]);

    useEffect(() => {
        if(profile && profile.user && profile.token){
            const token = profile.token;
            if (token) {
                const decodedToken = decode(token);
          
                if (decodedToken.exp * 1000 > new Date().getTime() && profile.user.companies){
                    navigate('/');
                } 
            }
            setProfile(JSON.parse(localStorage.getItem('profile')));
        }
    }, [profile, dispatch]);


    const handleEmailChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        // const regex = /^[a-zA-Z0-9.]+@ragmirsot.com+$/;
        // const valid = regex.test(value);
        const valid = true;

        setEmail({...email, value: value, valid: valid});
    }
    const handlePassChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const valid = (value.length > 7);
        setPassword({...password, value: value, valid: valid});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {email: email.value, password: password.value, remember: remember};
        dispatch(login(formData));
    }

    return(
        <div id="login" className='bg-lightblue'>
            <form className='bg-white' onSubmit={handleSubmit}>
                <div className='log-in-content'>
                    <h4 className='title-semibold-24-32 text-center'>{dictionary.login[lang]}</h4>
                    <div className='buttons'>
                        <div className='textfield-container'>
                            <div className='textfields-48px'>
                                <label className='title button-regular-16-16 color-black'>{dictionary.email[lang]}</label>
                                <input className='input' type="text" name="email" placeholder={dictionary.enterCorporateEmail[lang]} value={email.value} onChange={handleEmailChange} />
                            </div>
                            <div className='textfields-48px'>
                                <label className='title button-regular-16-16 color-black'>{dictionary.password[lang]}</label>
                                <input className='input button-regular-16-16' type="password" name="password" placeholder={dictionary.enterPassword[lang]} value={password.value} onChange={handlePassChange} />
                            </div>
                            {error && (
                                <div className='errors'>
                                    {dictionary[error][lang]}
                                </div>
                            )}
                        </div>
                        <div className='more body-regular-16-20'>
                            <label className='checkbox remember'>
                                <input type="checkbox" checked={remember} onChange={(e)=>{setRemember(e.target.checked)}} />
                                {dictionary.rememberMe[lang]}
                            </label>
                            <a href='/login/reset'>{dictionary.forgotPassword[lang]}</a>
                        </div>
                        <button type="submit" className="button-48px button-regular-16-16" disabled={!email.valid || !password.valid} >{dictionary.loginBtn[lang]}</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;