import * as api from '../api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { ARCHIVE_REQUEST, CLEAR_ADMIN_STATUSES, DOWNLOAD_CONSULTATION_REQUESTS, FETCH_CONSULTATION_ANALYTICS, FETCH_CONSULTATION_REQUESTS, FETCH_CONSULTATION_YEARS } from '../constants/actionTypes';

export const getConsultationYears = () => async (dispatch) => {
    try {
        const data = await api.fetchConsultationYears();
        dispatch({type: FETCH_CONSULTATION_YEARS, payload: data});
    } catch (error) {
        console.log(error);
    }
}
export const getConsultationRequests = (status, start, end, page, year) => async (dispatch) => {
    try {
        const data = await api.fetchConsultationRequests(status,start, end, page, year);
        dispatch({type: FETCH_CONSULTATION_REQUESTS, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const getConsultationAnalytics = (start, end, group) => async (dispatch) => {
    try {
        const data = await api.fetchConsultationAnalytics(start, end, group);
        dispatch({type: FETCH_CONSULTATION_ANALYTICS, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const archiveRequest = (id) => async (dispatch) => {
    try {
        dispatch({type: CLEAR_ADMIN_STATUSES});
        const data = await api.archiveRequest(id);
        dispatch({type: ARCHIVE_REQUEST, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const deleteRequest = (id) => async (dispatch) => {
    try {
        dispatch({type: CLEAR_ADMIN_STATUSES});
        const data = await api.deleteRequest(id);
        dispatch({type: ARCHIVE_REQUEST, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const downloadArchiveAsXLSX = (year, lang) => async (dispatch) => {
    const dictionary = {
        name: {ru: 'Имя', kz: 'Аты-жөні', en: 'Name'},
        email: {ru: 'Email', kz: 'Email', en: 'Email'},
        direction: {ru: 'Направление', kz: 'Бағыт', en: 'Direction'},
        service: {ru: 'Услуга', kz: 'Қызмет', en: 'Service'},
        date: {ru: 'Дата', kz: 'Күні', en: 'Date'},
        lang: {ru: 'Язык', kz: 'Тіл', en: 'Language'},
        ru: 'RU',
        kz: 'KZ',
        en: 'EN',
        consultationRequests: {ru: 'Запросы на консультацию (Архив', kz: 'Кеңес беру өтініштері (Мұрағат', en: 'Consultation Requests (Archive'}
    };

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const today = new Date();
    const fileName = dictionary.consultationRequests[lang] + ' ' + year + ') ' + moment(today).format('DD-MM-YYYY');

    try {
        dispatch({type: CLEAR_ADMIN_STATUSES});
        const response = await api.fetchAllArchiveRequestsByYear(year);


        if(response.status >= 200 && response.status < 300){
            const newArr = response.data.map((el,key)=>{
                return ({
                    ['#']: (key+1), 
                    [dictionary.name[lang]]: el.name, 
                    [dictionary.email[lang]]: el.email, 
                    [dictionary.direction[lang]]: el.direction ? el.direction[lang] : ' - ', 
                    [dictionary.service[lang]]: el.service ? el.service[lang] : ' - ', 
                    [dictionary.lang[lang]]: dictionary[el.lang], 
                    [dictionary.date[lang]]: el.date, 
                })
            })
    
            const ws = XLSX.utils.json_to_sheet(newArr);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
    
        }
        dispatch({type: DOWNLOAD_CONSULTATION_REQUESTS, payload: response});
    } catch (error) {
        console.log(error);
    }
}