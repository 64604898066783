import React from "react";

import './styles.scss';

const ProgressBar = ({value=0}) =>{
    return (
        <div className="progress-bar">
            <div style={{width:`${value}%`}}></div>
        </div>
    );
};

export default ProgressBar;