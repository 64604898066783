import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import decode from 'jwt-decode';

import { reset } from '../../actions/user';

import './styles.scss';

const dictionary = {
    doestNotExist:{
        ru: 'Пользователя с такой почтой не существует.\nПопробуйте еще раз.', 
        kz: 'Мұндай поштасы бар пайдаланушы жоқ.\nТағы да қайталап көріңіз.',
        en: 'There is no user with this email.\nTry again.'
    },
    somethingWentWrong:{
        ru: 'Что-то пошло не так.\nПовторите попытку позже.', 
        kz: 'Бірдеңе дұрыс болмады.\nӘрекетті кейінірек қайталаңыз.',
        en: 'Something went wrong.\nTry again later.'
    },
    explenation: {
        ru:'Введите корпоративную почту, на который мы отправим ссылку для восстановления',
        kz:'Қалпына келтіру сілтемесін жіберетін корпоративтік электрондық поштаңызды енгізіңіз',
        en:'Enter your corporate email address to which we will send a recovery link'
    },
    accountRecovery: {
        ru:'Восстановление аккаунта',
        kz:'Есептік жазбаны қалпына келтіру',
        en:'Account Recovery'
    },
    send: {
        ru:'Отправить',
        kz:'Жіберу',
        en:'Send'
    },
    corporateEmail: {
        ru:'Корпоративная почта',
        kz:'Корпоративтік пошта',
        en:'Corporate email'
    },
    enterCorporateEmail: {
        ru:'Введите корпоративную почту',
        kz:'Корпоративтік поштаңы енгізіңіз',
        en:'Enter corporate email'
    },
}

const Reset = () => {
    const [email, setEmail] = useState({value: '', valid: false, error: ''});
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [error, setError] = useState('');

    const {lang} = useSelector((state)=>state.global);
    const {resetPassStatus} = useSelector((state)=>state.users);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        if(resetPassStatus){
            switch (resetPassStatus) {
                case 200:
                    setError('');
                    break;
                case 404:
                    setError('doestNotExist')
                    break;
                default:
                    setError('somethingWentWrong')
                    break;
            }
        }
    }, [resetPassStatus]);

    useEffect(() => {
        if(profile && profile.user && profile.token){
            const token = profile.token;
            if (token) {
                const decodedToken = decode(token);
          
                if (decodedToken.exp * 1000 > new Date().getTime()) 
                    navigate('/')
            }
            setProfile(JSON.parse(localStorage.getItem('profile')));
        }
    }, [profile, dispatch]);


    const handleEmailChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        // const regex = /^[a-zA-Z0-9.]+@ragmirsot.com+$/;
        // const valid = regex.test(value);
        const valid = true;

        setEmail({...email, value: value, valid: valid});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {email: email.value};
        dispatch(reset(formData));
    }

    return(
        <div id="reset" className='bg-lightblue'>
            <form className='bg-white' onSubmit={handleSubmit}>
                <div className='log-in-content'>
                    <h4 className='title-semibold-24-32 text-center mb-3'>{dictionary.accountRecovery[lang]}</h4>
                    <div className='buttons'>
                        <div className='regular-16-20 color-darkgrey'>{dictionary.explenation[lang]}</div>
                        <div className='textfield-container'>
                            <div className='textfields-48px'>
                                <label className='title button-regular-16-16 color-black'>{dictionary.corporateEmail[lang]}</label>
                                <input className='input' type="text" name="email" placeholder={dictionary.enterCorporateEmail[lang]} value={email.value} onChange={handleEmailChange} />
                            </div>
                            {error && (
                                <div className='errors'>
                                    {dictionary[error][lang]}
                                </div>
                            )}
                        </div>
                        <button type="submit" className="button-48px button-regular-16-16" disabled={!email.valid} >{dictionary.send[lang]}</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Reset;