import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { archiveRequest, deleteRequest, downloadArchiveAsXLSX, getConsultationRequests, getConsultationYears } from '../../actions/consultation';
import moment from 'moment';
import './styles.scss';
import Modal from '../Modals/Modal';
import LoadingModal from '../Modals/LoadingModal';
import Loading from '../Loading/Loading';
const dictionary = {
    name: {ru: 'Имя', en: 'Name', kz: 'Аты-жөні'},
    email: {ru: 'Электронная почта', en: 'Email', kz: 'Электрондық пошта'},
    direction: {ru: 'Направление', en: 'Direction', kz: 'Бағыт'},
    service: {ru: 'Услуга', en: 'Service', kz: 'Қызмет'},
    lang: {ru: 'Язык', en: 'Language', kz: 'Тіл'},
    lastWeek: {ru: 'за неделю',kz: '',en: ''},
    lastMonth: {ru: 'за месяц',kz: '',en: ''},
    lastHalfYear: {ru: 'за полгода',kz: '',en: ''},
    lastYear: {ru: 'за год',kz: '',en: ''},
    requestCount: {ru: 'Всего заявок',kz: 'Өтініштердің жалпы саны:',en: 'Total request'},
    January: {ru: 'Январь',kz: 'Қаңтар',en: 'January'},
    February: {ru: 'Февраль',kz: 'Ақпан',en: 'February'},
    March: {ru: 'Март',kz: 'Наурыз',en: 'March'},
    April: {ru: 'Апрель',kz: 'Сәуір',en: 'April'},
    May: {ru: 'Май',kz: 'Мамыр',en: 'May'},
    June: {ru: 'Июнь',kz: 'Маусым',en: 'June'},
    July: {ru: 'Июль',kz: 'Щілде',en: 'July'},
    August: {ru: 'Август',kz: 'Тамыз',en: 'August'},
    September: {ru: 'Сентябрь',kz: 'Қыркүйек',en: 'September'},
    October: {ru: 'Октябрь',kz: 'Қазан',en: 'October'},
    November: {ru: 'Ноябрь',kz: 'Қараша',en: 'November'},
    December: {ru: 'Декабрь',kz: 'Желтоқсан',en: 'December'},
    active: {ru: 'Активные', kz: 'Актив', en: 'Active'},
    archive: {ru: 'Архивированные', kz: 'Мұрағатталған', en: 'Archived'},
    archiveBtn: {ru: 'Архивировать', kz: 'Мұрағат', en: 'Archive'},
    deleteBtn: {ru: 'Удалить', kz: 'Жою', en: 'Delete'},
    requests: {ru: 'Заявки', kz: 'Өтініштер', en: 'Requests'},
    download: {ru: 'Скачать', kz: 'Жүктеп алу', en: 'Download'},
}
const today = new Date(new Date().setUTCHours(0,0,0,0));
const oneDay = (1000 * 60 * 60 * 24);
const rangeInitials = {start: new Date(today.valueOf() - (6 * oneDay)), end: today, group: 'lastWeek'};
const RagMirsotTable = () => {
    const {consultations, page: pg, total, count, consultationYears, limit, archiveDownloadStatus, reqArchiveStatus, reqDeleteStatus} = useSelector((state) => state.admin);
    const {lang} = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const [requestsStatus, setRequestsStatus] =  useState('new');

    const [page, setPage] = useState(pg ? pg : 1);
    const [year, setYear] = useState(null);

    const [tableRange, setTableRange] = useState(rangeInitials);

    useEffect(() => {
        if(requestsStatus === 'new'){
            setYear(null);
            dispatch(getConsultationRequests(requestsStatus, tableRange.start, tableRange.end, page, null));
        }
        else if(requestsStatus === 'archive'){
            if(year){
                dispatch(getConsultationRequests(requestsStatus, null, null, page, year));
            }
            else if(!consultationYears || consultationYears.length===0){
                dispatch(getConsultationYears());
                setPage(1);
            }
        }
    }, [requestsStatus, tableRange, page, year]);

    useEffect(()=>{
        if(year){
            setPage(1)
        }
    }, [year])

    const changeTableRange = (e) => {
        // e.preventDefault();
        const value = e.currentTarget.dataset.range;
        let range = rangeInitials;
        const tmpStart = new Date(today.valueOf());
        switch (value) {
            case 'lastWeek':
                range.start = new Date(tmpStart.valueOf() - (6*oneDay));
                range.group = 'lastWeek';
                break;
            case 'lastMonth': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-1));
                range.group = 'lastMonth';
                break;
            case 'lastHalfYear': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-6));
                range.group = 'lastHalfYear';
                break;
            case 'lastYear': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-12));
                range.group = 'lastYear';
                break;
        }
        // console.log(range);
        setTableRange({...tableRange, start: range.start, group: range.group});
        setPage(1);
    }

    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [archiveId, setArchiveId] = useState(null);
    const [reqUsername, setReqUsername] = useState('');
    const [modalIsLoading, setModalIsLoading] = useState(false);

    useEffect(()=>{
        if(!show){
            setDeleteId(null);
            setArchiveId(null);
            setReqUsername('');
        }
    }, [show])

    const deleteConsultationRequest = (e) => {
        e.preventDefault();
        if(deleteId){
            setModalIsLoading(true);
            dispatch(deleteRequest(deleteId));
        }
        setShow(false)
    }
    const archiveConsultationRequest = (e) => {
        e.preventDefault();
        if(archiveId){
            setModalIsLoading(true);
            dispatch(archiveRequest(archiveId));
        }
        setShow(false)
    }

    const openArchiveModal = (e) =>{
        e.preventDefault();
        const id = e.currentTarget.dataset.id;
        const username = e.currentTarget.dataset.name;
        setArchiveId(id);
        setReqUsername(username);
        console.log(username)
        setShow(true);
    }
    const openDeleteeModal = (e) =>{
        e.preventDefault();
        const id = e.currentTarget.dataset.id;
        const username = e.currentTarget.dataset.name;
        setDeleteId(id);
        setReqUsername(username);
        setShow(true);
    }
    
    const downloadTable = (e) =>{
        e.preventDefault();
        setModalIsLoading(true);
        dispatch(downloadArchiveAsXLSX(year, lang));
    }

    useEffect(()=>{
        if(archiveDownloadStatus || reqArchiveStatus || reqDeleteStatus){
            setModalIsLoading(false);
        }
    }, [archiveDownloadStatus, reqArchiveStatus, reqDeleteStatus])

    return (
        <div className='table-container'>
            {modalIsLoading && (
                <LoadingModal />
            )}
            {archiveId && reqUsername && (
                <Modal type='action' actionType='archive' show={show} setShow={setShow} action={archiveConsultationRequest} userName={reqUsername}/>
            )}
            {deleteId && reqUsername && (
                <Modal type='action' actionType='deleteRequest' show={show} setShow={setShow} action={deleteConsultationRequest} userName={reqUsername}/>
            )}
            <h4 className='semibold-32-48 mb-2'>Заявки на консультацию</h4>
            <div className='tabs'>
                <div onClick={(e)=>{e.preventDefault(); setRequestsStatus('new')}} className={`tab ${requestsStatus==='new' ? 'active': ''}`}>{dictionary.active[lang]}</div>
                <div onClick={(e)=>{e.preventDefault(); setRequestsStatus('archive')}} className={`tab ${requestsStatus==='archive' ? 'active': ''}`}>{dictionary.archive[lang]}</div>
            </div>
            <div className='tab-contents'>
                <div className={`tab-content ${requestsStatus==='new' ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='regular-16-20'>
                            {dictionary.requestCount[lang]}: {consultations ? count : 0}
                        </div>
                        <div className='dropdown'>
                            <button className='dropbtn regular-16-20'>{
                                tableRange.start.getFullYear() === tableRange.end.getFullYear() ? 
                                    `${dictionary[moment(tableRange.start).format('MMMM')][lang]} ${moment(tableRange.start).format('DD')}` 
                                    : 
                                    `${dictionary[moment(tableRange.start).format('MMMM')][lang]} ${moment(tableRange.start).format('DD, YYYY')}`
                            } - {dictionary[moment(tableRange.end).format('MMMM')][lang]} {moment(tableRange.end).format('DD, YYYY')} {dictionary[tableRange.group][lang]
                            }</button>
                            <ul className='dropcontent'>
                                <li onMouseDown={changeTableRange} data-range="lastWeek">
                                    {dictionary.lastWeek[lang]}
                                </li>
                                <li onMouseDown={changeTableRange} data-range="lastMonth">
                                    {dictionary.lastMonth[lang]}
                                </li>
                                <li onMouseDown={changeTableRange} data-range="lastHalfYear">
                                    {dictionary.lastHalfYear[lang]}
                                </li>
                                <li onMouseDown={changeTableRange} data-range="lastYear">
                                    {dictionary.lastYear[lang]}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{dictionary.name[lang]}</th>
                                <th>{dictionary.email[lang]}</th>
                                <th>{dictionary.direction[lang]}</th>
                                <th>{dictionary.service[lang]}</th>
                                <th>{dictionary.lang[lang]}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {consultations && consultations.length > 0 && consultations.map((el, key) => (
                                <tr key={key}>
                                    <td>{((page-1) * limit) + key+1}</td>
                                    <td>{el.name}</td>
                                    <td>{el.email}</td>
                                    <td>{el.direction ? el.direction[lang] : '—'}</td>
                                    <td>{el.service ? el.service[lang] : '—'}</td>
                                    <td>{el.lang}</td>
                                    <td className='action' data-id={el._id} data-name={el.name} onClick={openArchiveModal}>{dictionary.archiveBtn[lang]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {total>1 && (
                        <div className='paginate-container'>
                            <div className='paginate'> 
                                {/* {{}} */}
                                    {page > 1 && (
                                        <span className='page-btn page-prev' onClick={(e)=>{e.preventDefault(); setPage(Number(page)-1)}}>
                                            <i></i>
                                        </span>
                                    )}
                                    {page > 3 && (
                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(1)}}>
                                            1
                                        </span>
                                    )}
                                    {page > 3 && (<span className='ellipsis' >...</span>)}
                                    {(page === total && page>2) && (
                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(Number(page)-2)}}>
                                            {Number(page)-2}
                                        </span>
                                    )}
                                    {page > 1 && (
                                        <span className={`page`} onClick={(e)=>{e.preventDefault(); setPage(Number(page)-1)}}>
                                            {Number(page)-1}
                                        </span>
                                    )}
                                    <span className='page active'>
                                        {page}
                                    </span>
                                    {page < total && (
                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(Number(page)+1)}}>
                                            {Number(page)+1}
                                        </span>
                                    )}
                                    {(page === 1 && page < total-1) && (
                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(Number(page)+2)}}>
                                            {Number(page)+2}
                                        </span>
                                    )}
                                    {page < total-2 && (<span className='ellipsis' >...</span>)}
                                    {page < total-2 && (
                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(total)}}>
                                            {total}
                                        </span>
                                    )}
                                    {page < total && (
                                        <span className='page-btn page-next' onClick={(e)=>{e.preventDefault(); setPage(Number(page)+1)}}>
                                            <i></i>
                                        </span>
                                    )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={`tab-content ${requestsStatus==='archive' ? 'active' : ''}`}>
                    {consultationYears ? (consultationYears.length > 0 && consultationYears.sort().reverse().map((consultationYear) => (
                        <div key={consultationYear}>
                            <div className={`accordion ${year===consultationYear ? 'active' : ''}`}>
                                <div className='accordion-header' onClick={(e)=>{e.preventDefault(); setYear(year === consultationYear ? null : consultationYear)}}>
                                    {dictionary.requests[lang]} {consultationYear}
                                    <span></span>
                                </div>
                                <div className='accordion-body'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{dictionary.name[lang]}</th>
                                                <th>{dictionary.email[lang]}</th>
                                                <th>{dictionary.direction[lang]}</th>
                                                <th>{dictionary.service[lang]}</th>
                                                <th>{dictionary.lang[lang]}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {consultations && consultations.length > 0 && consultations.map((el, key) => (
                                                <tr key={key}>
                                                    <td>{((page-1) * limit) + key+1}</td>
                                                    <td>{el.name}</td>
                                                    <td>{el.email}</td>
                                                    <td>{el.direction ? el.direction[lang] : '—'}</td>
                                                    <td>{el.service ? el.service[lang] : '—'}</td>
                                                    <td>{el.lang}</td>
                                                    <td className='action' data-id={el._id} data-name={el.name} onClick={openDeleteeModal}>{dictionary.deleteBtn[lang]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {(!consultations || consultations.length===0)&& (
                                        <Loading size="md" color="black" />
                                    ) }
                                </div>
                            </div>
                            {year===consultationYear && (
                                <div className='d-flex pt-3 pb-3 justify-content-between align-items-center'>
                                    <div className='button-48' onClick={downloadTable}>{dictionary.download[lang]}</div>
                                    {total && (
                                        <div className='paginate-container'>
                                            <div className='paginate'> 
                                                    {page > 1 && (
                                                        <span className='page-btn page-prev' onClick={(e)=>{e.preventDefault(); setPage(Number(page)-1)}}>
                                                            <i></i>
                                                        </span>
                                                    )}
                                                    {page > 3 && (
                                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(1)}}>
                                                            1
                                                        </span>
                                                    )}
                                                    {page > 3 && (<span className='ellipsis' >...</span>)}
                                                    {(page === total && page>2) && (
                                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(Number(page)-2)}}>
                                                            {Number(page)-2}
                                                        </span>
                                                    )}
                                                    {page > 1 && (
                                                        <span className={`page`} onClick={(e)=>{e.preventDefault(); setPage(Number(page)-1)}}>
                                                            {Number(page)-1}
                                                        </span>
                                                    )}
                                                    <span className='page active'>
                                                        {page}
                                                    </span>
                                                    {page < total && (
                                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(Number(page)+1)}}>
                                                            {Number(page)+1}
                                                        </span>
                                                    )}
                                                    {(page === 1 && page < total-1) && (
                                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(Number(page)+2)}}>
                                                            {Number(page)+2}
                                                        </span>
                                                    )}
                                                    {page < total-2 && (<span className='ellipsis' >...</span>)}
                                                    {page < total-2 && (
                                                        <span className='page' onClick={(e)=>{e.preventDefault(); setPage(total)}}>
                                                            {total}
                                                        </span>
                                                    )}
                                                    {page < total && (
                                                        <span className='page-btn page-next' onClick={(e)=>{e.preventDefault(); setPage(Number(page)+1)}}>
                                                            <i></i>
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))) : (
                        <Loading />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RagMirsotTable;