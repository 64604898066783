import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPagesByParent } from '../../../actions/page';
import Block from '../../subcomponents/Block/Block';
import ImageBlock from '../../subcomponents/Block/ImageBlock';

import './styles.scss';

const dictionary = {
    directions: {
        ru: "Направления",
        en: "Direction",
        kz: "Бағыттар"
    },
    services: {
        ru: "Услуги",
        en: "Services",
        kz: "Қызметтер"
    }
}

const Subpage = ({pagename=""}) =>{
    const {lang} = useSelector((state)=>state.global)
    const {subpages} = useSelector((state)=>state.pages);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(pagename && (pagename==="directions" || pagename==="services")){
            dispatch(getPagesByParent(pagename));
        }
    },[pagename]);


    const [activeTab, setActiveTab] = useState(0);
    const setTab = (index) =>{
        setActiveTab(index);
    } 

    return (pagename==="directions" || pagename==="services") && (
        <div id="subpage" className={pagename}>
            <h1 className='semibold-32-48'>{dictionary[pagename][lang]}</h1>
            <div className='tabs'>
                {subpages && subpages.map((subpage, key)=>(
                    <div onClick={() => setTab(key)} className={`tab ${activeTab===key ? 'active' : ''}`} key={key}>
                        {dictionary[pagename][lang]} {key+1} 
                    </div> 
                ))}
            </div>
            <div className='tab-contents'>
                {subpages && subpages.map((subpage, key)=>(
                    <div className={`tab-content ${activeTab===key ? 'active' : ''}`} key={key}>
                        <ImageBlock 
                            subtitle="Символ"
                            type="image"
                            className="icon"
                            image={subpage.images[0]}
                            _id={subpage.images[0]._id}
                            actions={["upload"]}
                        />
                        <Block items={[
                            {subtitle: "Наименование", type:"text", text: subpage.title, fieldname: 'title', _id: subpage._id},
                            {subtitle: "Текст блока", type:"text", text: subpage.description, limit: 500, fieldname: 'description', _id: subpage._id},
                        ]} index={subpage._id} />
                        <ImageBlock 
                            subtitle="Фоновая картинка"
                            type="carousell"
                            size={4}
                            images={subpage.images.slice(1)}
                            _id={subpage._id}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
};

export default Subpage;