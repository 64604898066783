// GLOBAL
export const LANG = "LANG";
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const FETCH_LANGS = 'FETCH_LANGS';
export const TOGGLE_LANGS = 'TOGGLE_LANGS';
export const CLEAR_LANG_STATUS = 'CLEAR_LANG_STATUS';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';

// USER
export const AUTH = 'AUTH';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FETCH_USERS = 'FETCH_USERS';
export const DELETE_USER = 'DELETE_USER';
export const ADD_USER = 'ADD_USER';
export const FETCH_ROLES = 'FETCH_ROLES';
export const CLEAR_USER_STATUSES = 'CLEAR_USER_STATUSES';


// PAGES
export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGES = 'FETCH_PAGES';
export const CLEAR_PAGE = 'CLEAR_PAGE';
export const FETCH_TEXTS = 'FETCH_TEXTS';
export const UPDATE_TEXT = 'UPDATE_TEXT';
export const FETCH_PAGES_BY_PARENT = 'FETCH_PAGES_BY_PARENT';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';

export const UPDATE_STAFF = 'UPDATE_STAFF';
export const CLEAR_PAGE_STATUSES = 'CLEAR_PAGE_STATUSES';
// IMAGE
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_IMAGES = 'UPDATE_IMAGES';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const CLEAR_IMAGE_STATUSES = 'CLEAR_IMAGE_STATUSES';



// ADMIN
export const FETCH_VISITORS = 'FETCH_VISITORS';
export const FETCH_CONSULTATION_REQUESTS = 'FETCH_CONSULTATION_REQUESTS';
export const FETCH_CONSULTATION_ANALYTICS = 'FETCH_CONSULTATION_ANALYTICS';
export const FETCH_CONSULTATION_YEARS = 'FETCH_CONSULTATION_YEARS';
export const CLEAR_CONSULTATION_REQUESTS = 'CLEAR_CONSULTATION_REQUESTS';
export const DOWNLOAD_CONSULTATION_REQUESTS = 'DOWNLOAD_CONSULTATION_REQUESTS';
export const CLEAR_ADMIN_STATUSES = 'CLEAR_ADMIN_STATUSES';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const ARCHIVE_REQUEST = 'ARCHIVE_REQUEST';

export const FETCH_CONVERSION_RATE = 'FETCH_CONVERSION_RATE';