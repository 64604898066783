import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageByName } from "../../../actions/page";
// import Edit from "../../Edit/Edit";
import Block from "../../subcomponents/Block/Block";
import ImageBlock from "../../subcomponents/Block/ImageBlock";

import './styles.scss';

const About = () => {
    const {page} = useSelector((state)=>state.pages);
    const {lang} = useSelector((state)=>state.global);
    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(!page){
            dispatch(getPageByName('about'));
        }
    },[dispatch, page]);

    const [activeTab, setActiveTab] = useState(0);
    const setTab = (index) =>{
        setActiveTab(index);
    } 

    return page && (
        <div id="about">
            <div className="blocks">
                <Block items={[
                    {subtitle: "Заголовок блока", type:"text", text: page.about_heading},
                    {subtitle: "Текст блока", type:"text", text: page.about_subheading}
                ]} title="Блок 1 - О нас" index="about_0" />
                <div className="block">
                    <div className="block-title">
                    Блок 2 - Карточки
                    </div>
                    <div className='tabs'>
                        {page.cards && page.cards.map((card, key)=>(
                            <div onClick={() => setTab(key)} className={`tab ${activeTab===key ? 'active' : ''}`} key={key}>
                                Карточка {key+1} 
                            </div> 
                        ))}
                    </div>
                    <div className='tab-contents'>
                        {page.cards && page.cards.map((card, key)=>(
                            <div className={`tab-content ${activeTab===key ? 'active' : ''}`} key={key}>
                                <Block items={[
                                    {subtitle: "Заголовок карточки", type:"text", fieldname: 'title', text: card.title, _id: card._id},
                                    {subtitle: "Текст блока", type:"text", fieldname: 'subtitle', text: card.subtitle, _id: card._id}
                                ]} index={card._id}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="block">
                    <div className="block-title">
                        Блок 3 - Команда Mirsot
                    </div>
                    <Block items={[
                        {subtitle: "Заголовок блока", type: "text", text: page.staff_title, _id: page.staff_title._id}
                    ]} index="about_1"/>
                    <div className='tabs'>
                        {page.staff && page.staff.map((stf, key)=>(
                            <div onClick={() => setTab(key)} className={`tab ${activeTab===key ? 'active' : ''}`} key={key}>
                                Сотрудник {key+1} 
                            </div> 
                        ))}
                    </div>
                    <div className='tab-contents'>
                        {page.staff && page.staff.map((stf, key)=>(
                            <div className={`tab-content ${activeTab===key ? 'active' : ''}`} key={key}>
                                <ImageBlock 
                                    subtitle="Фото сотрудника"
                                    type="image"
                                    image={stf.image}
                                    _id={stf.image._id} 
                                    className="staffImage"
                                    actions={["upload"]}
                                />
                                <Block items={[
                                    {subtitle: "Имя Фамилия сотрудника", type:"text", fieldname: 'name', text: stf.name, _id: stf._id},
                                    {subtitle: "Долженность сотрудника", type:"text", fieldname: 'position', text: stf.position, _id: stf._id},
                                    {subtitle: "Текст описание", type:"text", fieldname: 'text', text: stf.text, _id: stf._id},
                                    {subtitle: "Почта сотрудника", type:"singleText", fieldname: 'email', singleText: stf.email, label: 'Почта', _id: stf._id}
                                ]} index={stf._id} />
                            </div>
                        ))}
                    </div>
                </div>
                <Block items={[
                    {subtitle: 'Заголовок блока', type: "text", text:page.about_carousell_title, _id: page.about_carousell_title._id}
                ]} title="Сертификаты"/>
                <ImageBlock 
                    subtitle='Изображения'
                    type="carousell"
                    images={page.images} 
                    size={6}
                    _id={page._id}
                />
            </div>
        </div> 
    );
}

export default About;