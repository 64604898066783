import * as api from '../api';
import { ADD_USER, AUTH, CHANGE_PASSWORD, CLEAR_LANG_STATUS, DELETE_USER, FETCH_LANGS, FETCH_ROLES, FETCH_USERS, RESET_PASSWORD, TOGGLE_LANGS } from '../constants/actionTypes';

export const login = (formData) => async (dispatch) => {
    try {
        const data = await api.login(formData);
        dispatch({type: AUTH, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const reset = (formData) => async (dispatch) => {
    try {
        const data = await api.resetPassword(formData);
        dispatch({type: RESET_PASSWORD, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const getUsers = () => async (dispatch) => {
    try {
        const data = await api.fetchUsers();
        dispatch({type: FETCH_USERS, payload: data});
    } catch (error) {
        console.log(error);
    }
}
export const deleteUser = (id) => async (dispatch)=>{
    try {
        const data = await api.deleteUser(id);
        dispatch({type:DELETE_USER, payload: data});
    } catch (error) {
        console.log(error);
    }
}
export const addUser = (formData) => async (dispatch)=>{
    try {
        const data = await api.addUser(formData);
        dispatch({type: ADD_USER, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const changePassword = (formData) => async (dispatch)=>{
    try {
        const data = await api.changePassword(formData);
        dispatch({type: CHANGE_PASSWORD, payload: data});
    } catch (error) {
        console.log(error);
    }
}


export const getRoles = () => async (dispatch) =>{
    try {
        const data = await api.fetchRoles();
        dispatch({type: FETCH_ROLES, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const getLangs = () => async (dispatch)=>{
    try {
        const data = await api.fetchLangs();
        dispatch({type: FETCH_LANGS, payload: data});
    } catch (error) {
        console.log(error);
    }
}

export const toggleLangs = (newLangs) => async (dispatch)=>{
    try {
        await dispatch({type: CLEAR_LANG_STATUS});
        const data = await api.toggleLangs({'langs':newLangs});
        dispatch({type: TOGGLE_LANGS, payload: data});
    } catch (error) {
        console.log(error);
    }
}