import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LANG } from "../../constants/actionTypes";

import './styles.scss';

const dictionary = {
    changeCompany:{ru:'Поменять кампанию', en:'Change company',kz:'Компанияны өзгерту'},
    logout: {ru: 'Выйти', en: 'Logout', kz: 'Шығу'}
}

const RagZakyatHeader = () => {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));
    const {lang} = useSelector((state)=>state.global);
 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(!lang)
            dispatch({type: LANG, lang: 'ru'});
    }, [lang]);

    const logout = (e) =>{
        e.preventDefault();
        localStorage.clear('profile');
        navigate('/login');
    }

    const changeLanguage = (e) => {
        e.preventDefault();
        dispatch({type: LANG, lang: e.currentTarget.dataset.lang});
    };
    return (
        <div id="adminheader" className="rag-zakyat">
            <a className="brand" href="/">
                <i className="logotype"></i>
            </a>
            <div className="buttons">
                {profile.user.companies.length > 1 && (
                    <a className="button-48px change-company" href="/login/choose">{dictionary.changeCompany[lang]}</a>
                )}
                <div className="button-48px logout" onClick={logout}>{dictionary.logout[lang]}</div>
                <div id="language" className="dropdown">
                    <a className="dropbtn">
                        {((!lang || lang==='ru') && (<>
                            RU
                        </>)) || (lang==='en' && (<>
                            EN
                        </>)) || (lang==='kz' && (<>
                            ҚАЗ
                        </>))}
                    </a>
                    <ul className="dropcontent">
                        <li data-lang="en" className={lang === 'en' ? 'hidden' : ''} onClick={changeLanguage}>
                            <a>
                                <i data-lang="en" className="lang-en"></i>EN
                            </a>
                        </li>
                        <li data-lang="ru" className={(!lang || lang === 'ru') ? 'hidden' : ''} onClick={changeLanguage}>
                            <a>
                                <i data-lang="ru" className="lang-ru"></i>RU
                            </a>
                        </li>
                        <li data-lang="kz" className={lang === 'kz' ? 'hidden' : ''} onClick={changeLanguage}>
                            <a>
                                <i data-lang="kz" className="lang-kz"></i>ҚАЗ
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RagZakyatHeader;