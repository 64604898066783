import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateText } from "../../../actions/page";

import './styles.scss';

const words = {
    russian: {ru: 'На русском',en: 'Russian',kz: 'Орысша'},
    english: {ru: 'На английском',en: 'English',kz: 'Ағылшынша'},
    kazakh: {ru: 'На казахском',en: 'Kazakh',kz: 'Қазақша'},
    save: {ru: 'Сохранить',en: 'Save',kz: 'Сақтау'},
}

const Edit = ({type=null, card,image, images, fieldname=null, carousellSize=0, placeholder='', singleText='', text={ru:'', kz: '', en: ''}, className="", limit=100,  handleChange, index, label="", groupText}) => {
     
    const {lang, link} = useSelector((state) => state.global);
    const {newText} = useSelector((state) => state.pages);
    const [tmpText, setTmpText] = useState({ru:'', kz: '', en: ''});
    const [tmpSingleText, setSingleTmpText] = useState(singleText);

    const handleTextChange = (e) => {
        // e.preventDefault();
        const value = e.target.value;
        switch (type) {
            case "text":
                const lang = e.target.dataset.lang;
                if(value.length <= limit){
                    handleChange("text", index, {...text, [lang]: value}, fieldname);
                }
                break;
            case "singleText":
                handleChange("singleText", index, value, fieldname); 
                break;      
        }
    }

    useEffect(()=>{
        if(type && type==='text'){
            let t = text ? text : {ru:'', kz: '', en: ''};
            t.ru = t.ru ? t.ru : '';
            t.en = t.en ? t.en : '';
            t.kz = t.kz ? t.kz : '';
            setTmpText(t);
        }
    }, [text]);
    useEffect(()=>{
        if(type && type==='singleText'){
            let t = singleText ? singleText : '';
            setSingleTmpText(t);
        }
    }, [singleText]);
    const updateTextareaHeight = () => {
        const tas = document.getElementsByTagName("textarea");
        for (let index = 0; index < tas.length; index++) {
            const ta = tas[index];
            ta.style.height = "";
            ta.style.height = ta.scrollHeight + "px";
        }
    }

    const focusParent = (e) =>{
        e.currentTarget.parentElement.classList.add('focus');
    }
    const blurParent = (e) =>{
        e.currentTarget.parentElement.classList.remove('focus');
    }


    // image
    const [tmpImages, setTmpImages] = useState([]);
    useEffect(()=>{
        if(images){
            let arr = new Array(carousellSize);
            for (let index = 0; index<carousellSize; index++) {
                arr[index] = {src: null};
            }
            for (let index = 0; index < images.length && index<carousellSize; index++) {
                const img = images[index];
                arr[index] = img;
            }
            setTmpImages(arr);
        }
    }, [images])
    const [tmpImage, setTmpImage] = useState(null);
    const [tmpFile, setTmpFile] = useState(null);
    useEffect(()=>{
        if(image){
            setTmpImage({...image, src: `${link}images/${image.src}`});
        }
    }, [image]);
    const uploadImage = (e) =>{
        const file = e.currentTarget.files.length>0 ? e.currentTarget.files[0] : null;
        if(file){
            setTmpFile(file);
            setTmpImage({...tmpFile, src: URL.createObjectURL(file)});
            handleChange("image", index, file, fieldname); 
        }
    }

    switch (type) {
        case "singleText":
            return (
                <div className="singletext-edit">
                    <div className="edit-subblock">
                        <label>{label}</label>
                        {groupText ? (
                            <div className="input-group">
                                <div className="input-group-text">{groupText}</div>
                                <input value={tmpSingleText} placeholder={placeholder} onChange={handleTextChange} onFocus={focusParent} onBlur={blurParent}  />
                            </div>
                        ) : (
                            <input value={tmpSingleText} placeholder={placeholder} onChange={handleTextChange} onFocus={focusParent} onBlur={blurParent}  />
                        )}
                    </div>
                </div>
            )
        case "text":
            return (
                <div className="text-edit">  
                    <div className="edit-subblock">
                        <div className="lang">{words.english[lang]}</div>
                        <div className="textarea">
                            <textarea value={tmpText.en} data-lang="en" className={className} onChange={handleTextChange} onFocus={focusParent} onBlur={blurParent} />
                            <span className="limit">{tmpText.en.length}/{limit}</span>
                        </div>
                    </div>
                    <div className="edit-subblock">
                        <div className="lang">{words.russian[lang]}</div>
                        <div className="textarea">
                            <textarea value={tmpText.ru} data-lang="ru" className={className} onChange={handleTextChange} onFocus={focusParent} onBlur={blurParent} />
                            <span className="limit">{tmpText.ru.length}/{limit}</span>
                        </div>
                    </div>
                    <div className="edit-subblock">
                        <div className="lang">{words.kazakh[lang]}</div>
                        <div className="textarea">
                            <textarea value={tmpText.kz} data-lang="kz" className={className} onChange={handleTextChange} onFocus={focusParent} onBlur={blurParent} />
                            <span className="limit">{tmpText.kz.length}/{limit}</span>
                        </div>
                    </div>
                </div>                
            );
        case "card": 
            return (
                <div className="card-edit">
                    <div className="edit-cards">
                        <div className="edit-cardsub">
                            <div className="lang">{words.english[lang]}</div>
                            <textarea value={card.title.en} data-lang="en" className={className} onChange={handleTextChange}  />
                            <textarea value={card.subtitle.en} data-lang="en" className={className} onChange={handleTextChange}  />
                        </div>
                        <div className="edit-cardsub">
                            <div className="lang">{words.russian[lang]}</div>
                            <textarea value={card.title.ru} data-lang="ru" className={className} onChange={handleTextChange}  />
                            <textarea value={card.subtitle.ru} data-lang="ru" className={className} onChange={handleTextChange}  />
                        </div>
                        <div className="edit-cardsub">
                            <div className="lang">{words.kazakh[lang]}</div>
                            <textarea value={card.title.kz} data-lang="kz" className={className} onChange={handleTextChange}  />
                            <textarea value={card.subtitle.kz} data-lang="kz" className={className} onChange={handleTextChange}  />
                        </div>
                    </div>
                    {/* <div className="d-flex">
                        <button className="ml-auto save-text-btn button-48 regular-16-16" onClick={saveText}>{words.save[lang]}</button>
                    </div> */}
                </div>
            );
        case "image": 
            return (
                <div className="image-edit">
                    <div className={`img-container ${className}`}>
                        {tmpImage && (
                            <img src={tmpImage.src} /> 
                        )}
                        <div className="img-overlay">
                            <div className="actions">
                                <label className="action-btn upload">
                                    <input type='file' name={tmpImage ? tmpImage._id : 'img'} accept="image/png, image/jpeg" onChange={uploadImage}/>
                                    <i></i>
                                </label>
                                <label className="action-btn delete">
                                    <i></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case "subpageIcon": 
            return (
                <div className="image-edit">
                    <div className="img-container icon">
                        <img src={`${link}images/${image.src}`} /> 
                        <div className="img-overlay">
                            <div className="actions">
                                <div className="action-btn upload">
                                    <i></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case "carousell":
            return (
                <div className="carousell">
                        
                    {tmpImages.map((img, key)=>(
                        <div className="carousell-item" key={key}>
                            <div className="image-edit">
                                <div className="img-container">
                                    {img.src ? (
                                        <>
                                            <img src={`${link}images/${img.src}`} /> 
                                            <div className="img-overlay">
                                                <div className="actions">
                                                    <div className="action-btn upload">
                                                        <i></i>
                                                    </div>
                                                    <div className="action-btn delete">
                                                        <i></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="noimg">
                                            <div className="actions">
                                                <div className="action-btn upload">
                                                    <i></i>
                                                    <span>Добавить картинку</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        default:
            return (<></>);
    }
};

export default Edit;