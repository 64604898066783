import axios from 'axios';
import { UPLOAD_PROGRESS } from '../constants/actionTypes';


// const API = axios.create({ baseURL: 'https://rag-mirsot-server.herokuapp.com/', validateStatus: function (status) { return true } });
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_DEV, validateStatus: function (status) { return true } });

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});

// PAGES
export const fetchPageByName = (name) => API.get(`/pages/${name}`);
export const fetchPages = () => API.get(`/pages`);
export const fetchTexts = (names) => API.get(`/pages/text?names=${names}`);
export const updateText = (data) => API.patch('/pages/text', data);
export const fetchPagesByParent = (name) => API.get(`/pages/parent/${name}`);
export const updateStaff = async (id, image, data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('image', image);
    bodyFormData.append('data', JSON.stringify(data));
    API.patch(`/pages/staff/${id}`, bodyFormData, {headers: {"Content-Type": "multipart/form-data"}});
}
export const updateItems = (items) => API.patch('/pages/update', items);
// IMGAE
export const updateImage = async (id, image, setProgress) => {
    let formData = new FormData();
    formData.append('image', image);
    const config = {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setProgress(percent);
        }
    }
    return await API.patch(`/pages/image/${id}`, formData,config);
}
export const updateImages = async (parentId, images, data, setProgress) => {
    let formData = new FormData();
    images.forEach(image => {
        formData.append('images', image);
    });
    formData.append('data', JSON.stringify(data));
    const config = {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setProgress(percent);
        }
    }
    return await API.patch(`/pages/images/${parentId}`, formData,config);
}
// ADMIN
export const login = (data) => API.post('/users/login', data);
export const resetPassword = (data) => API.post('/users/reset', data);
export const fetchUsers = () => API.get('/users');
export const deleteUser = (id) => API.delete(`/users/${id}`); 
export const addUser = (data) => API.post(`/users/adduser`, data); 
export const changePassword = (data) => API.patch(`/users/change_password`, data); 

export const fetchLangs = ()=>API.get('/langs');
export const toggleLangs = (data)=>API.patch('/langs/toggleMany', data);

export const fetchRoles = () => API.get('/roles');

export const fetchVisitors = (start, end, group) => API.get(`/visitors/range?start=${start}&end=${end}&group=${group}`);
// CONSULTATIONS
export const fetchConsultationRequests = (status, start, end, page, year) => API.get(`/consultations/${status}?${page ? `page=${page}` : ''}${year ? `&year=${year}` : ''}${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`);
export const fetchConsultationAnalytics = (start, end, group) => API.get(`/consultations/analytics?start=${start}&end=${end}&group=${group}`);
export const fetchConsultationYears = () => API.get('/consultations/years');
export const fetchAllArchiveRequestsByYear = (year) => API.get(`/consultations/download_archive?year=${year}`);
export const archiveRequest = (id) => API.patch(`/consultations/${id}`);
export const deleteRequest = (id) => API.delete(`/consultations/${id}`);


export const fetchConversionRate = (start, end) => API.get(`/rag_zakyat/conversion/rate?${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`);
export const fetchZakyatVisitors = (start, end, group) => API.get(`/rag_zakyat/visitor?start=${start}&end=${end}&group=${group}`);