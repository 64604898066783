import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getVisitors } from '../../actions/rag_zakyat/visitor';
import Chart from '../Chart/Chart';
import Table from '../Table/index';

import './styles.scss';

const dictionary = {
    lastWeek: {ru: 'за неделю',kz: '',en: ''},
    lastMonth: {ru: 'за месяц',kz: '',en: ''},
    lastHalfYear: {ru: 'за полгода',kz: '',en: ''},
    lastYear: {ru: 'за год',kz: '',en: ''},
    January: {ru: 'Январь',kz: 'Қаңтар',en: 'January'},
    February: {ru: 'Февраль',kz: 'Ақпан',en: 'February'},
    March: {ru: 'Март',kz: 'Наурыз',en: 'March'},
    April: {ru: 'Апрель',kz: 'Сәуір',en: 'April'},
    May: {ru: 'Май',kz: 'Мамыр',en: 'May'},
    June: {ru: 'Июнь',kz: 'Маусым',en: 'June'},
    July: {ru: 'Июль',kz: 'Щілде',en: 'July'},
    August: {ru: 'Август',kz: 'Тамыз',en: 'August'},
    September: {ru: 'Сентябрь',kz: 'Қыркүйек',en: 'September'},
    October: {ru: 'Октябрь',kz: 'Қазан',en: 'October'},
    November: {ru: 'Ноябрь',kz: 'Қараша',en: 'November'},
    December: {ru: 'Декабрь',kz: 'Желтоқсан',en: 'December'},
}
const today = new Date(new Date().setUTCHours(0,0,0,0));
const oneDay = (1000 * 60 * 60 * 24);
const rangeInitials = {start: new Date(today.valueOf() - (6 * oneDay)), end: today, group: 'lastWeek'};

const RagZakyatDashboard = () => {
    const user = JSON.parse(localStorage.getItem('profile'))?.user;
    const navigate = useNavigate();
    if(user && user.role.name === 'editor'){
        navigate('/cabinet');
    }
    
    const {visitors} = useSelector((state) => state.admin);
    const {lang} = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const [dataRange, setDataRange] = useState(rangeInitials);

    useEffect(() => {
        dispatch(getVisitors(dataRange.start, dataRange.end, dataRange.group));
    }, [dispatch, dataRange]);


    const changeDataRange = (e) => {
        // e.preventDefault();
        const value = e.currentTarget.dataset.range;
        let range = rangeInitials;
        const tmpStart = new Date(today.valueOf());
        switch (value) {
            case 'lastWeek':
                range.start = new Date(tmpStart.valueOf() - (6*oneDay));
                range.group = 'lastWeek';
                break;
            case 'lastMonth': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-1));
                range.group = 'lastMonth';
                break;
            case 'lastHalfYear': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-6));
                range.group = 'lastHalfYear';
                break;
            case 'lastYear': 
                range.start = new Date(tmpStart.setMonth(tmpStart.getMonth()-12));
                range.group = 'lastYear';
                break;
        }
        // console.log(range);
        setDataRange({...dataRange, start: range.start, group: range.group});
    }

    return (
        <div id="dashboard">
            <div className='charts mb-5'>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <h4 className='semibold-32-48'>Аналитика</h4>
                    <div className='dropdown'>
                        <button className='dropbtn regular-16-20'>{
                            dataRange.start.getFullYear() === dataRange.end.getFullYear() ? 
                                `${dictionary[moment(dataRange.start).format('MMMM')][lang]} ${moment(dataRange.start).format('DD')}` 
                                : 
                                `${dictionary[moment(dataRange.start).format('MMMM')][lang]} ${moment(dataRange.start).format('DD, YYYY')}`
                        } - {dictionary[moment(dataRange.end).format('MMMM')][lang]} {moment(dataRange.end).format('DD, YYYY')} {dictionary[dataRange.group][lang]
                        }</button>
                        <ul className='dropcontent'>
                            <li onMouseDown={changeDataRange} data-range="lastWeek">
                                {dictionary.lastWeek[lang]}
                            </li>
                            <li onMouseDown={changeDataRange} data-range="lastMonth">
                                {dictionary.lastMonth[lang]}
                            </li>
                            <li onMouseDown={changeDataRange} data-range="lastHalfYear">
                                {dictionary.lastHalfYear[lang]}
                            </li>
                            <li onMouseDown={changeDataRange} data-range="lastYear">
                                {dictionary.lastYear[lang]}
                            </li>
                        </ul>
                    </div>
                </div>
                {visitors && (
                    <Chart data={visitors.analytics} todayCount={visitors.today} id="visitorslinechart" title="Посетителей сегодня" label="Посетители"/>
                )}
            </div>
            <Table />
        </div>
    );
};

export default RagZakyatDashboard;