import * as api from '../api';
import {  FETCH_VISITORS } from '../constants/actionTypes';


export const getVisitors = (start, end, group) => async (dispatch) => {
    try {
        const data = await api.fetchVisitors(start, end, group);
        dispatch({type: FETCH_VISITORS, payload: data});
    } catch (error) {
        console.log(error);
    }
}