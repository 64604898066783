import { useNavigate } from "react-router-dom";
import { AUTH, FETCH_USERS, RESET_PASSWORD, DELETE_USER, ADD_USER, CHANGE_PASSWORD, FETCH_ROLES, CLEAR_USER_STATUSES } from "../constants/actionTypes";

const userReducers = (state = {authData: null, authStatus:null, passwordChanged: null, roles: [], delete_status: null, new_user_status: null}, action) => {
    switch (action.type) {
        case AUTH:
            if(!!action.payload && !!action.payload.data && action.payload.status === 200){
                localStorage.setItem('profile', JSON.stringify({...action.payload.data}));        
                return { ...state, authData: action.payload?.data, authStatus: action.payload.status};
            }
            return {...state, authData: null, authStatus: action.payload?.status};
        case RESET_PASSWORD:
            return {...state, resetPassStatus: action.payload?.status};
        case FETCH_USERS:
            return {...state, users: action.payload.data};
        case DELETE_USER:
            return {...state, users: action.payload.data, delete_status: action.payload.status};
        case ADD_USER:
            return {...state, users: action.payload.data, new_user_status: action.payload.status};
        case CHANGE_PASSWORD:
            return {...state, passwordChanged: action.payload.status};
        case FETCH_ROLES: 
            return {...state, roles: action.payload.data};
        case CLEAR_USER_STATUSES:
            return {...state, passwordChanged: null, authStatus: null, delete_status: null, resetPassStatus: null, new_user_status: null}
        default:
            return state;
    }
};
export default userReducers;