import React from "react";
import { useSelector } from "react-redux";
import RagMirsotHeader from "./RagMirsotHeader";
import RagZakyatHeader from "./RagZakyatHeader";

const Header = () => {
    const {company} = useSelector((state) => state.global);

    switch (company) {
        case 'rag-mirsot':
            return <RagMirsotHeader />    
        case 'rag-zakyat':
            return <RagZakyatHeader />
        default:
            return null;
    }
};

export default Header;