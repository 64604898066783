import React from "react";

import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Admin from "./components/Admin/Admin";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/index";
import AdminHome from './components/Pages/Home/Home';
import AdminAbout from './components/Pages/About/About';
import Subpage from "./components/Pages/Subpage/Subpage";
import Reset from "./components/Login/Reset";
import Contacts from "./components/Pages/Contacts/Contacs";
import Cabinet from "./components/Cabinet/Cabinet";
import ChooseCompany from "./components/Login/ChooseCompany";


const App = () => {

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<>
                    <Outlet />
                </>}>
                    <Route path="login/reset" element={<Reset />} />
                    <Route path="login/choose" element={<ChooseCompany />} />
                    <Route path="login" element={<Login />} />
                    <Route path="" element={<Admin />}>
                        <Route path="" element={<Dashboard />} />
                        <Route path="home" element={<AdminHome />} />
                        <Route path="about" element={<AdminAbout />} />
                        <Route path="directions" element={<Subpage pagename="directions" />} />
                        <Route path="services" element={<Subpage pagename="services" />} />
                        <Route path="contacts" element={<Contacts />} />
                        <Route path="cabinet" element={<Cabinet />} />
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
