import React from "react";
import { useSelector } from "react-redux";
import RagMirsotMenu from "./RagMirsotMenu";

const Menu = () => {
    const {company} = useSelector((state)=>state.global);

    switch(company){
        case 'rag-mirsot':
            return <RagMirsotMenu />
        default:
            return null;
    }
}

export default Menu;