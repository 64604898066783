import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import { CHANGE_COMPANY } from "../../constants/actionTypes";

import RagMirsotLogo from '../../utilities/logos/rag-mirsot.svg'
import RagZakyatLogo from '../../utilities/logos/rag-zakyat.svg'

const companyLogos =  {
    "rag-mirsot": RagMirsotLogo,
    "rag-zakyat": RagZakyatLogo,
}

const ChooseCompany = () => {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [companies, setCompanies] = useState([]);

    useEffect(()=>{
        if(profile && profile.user && profile.user.companies){
            profile.user.companies.forEach(company => {
                setCompanies(old => [...old, company.name])
            });
        }
    }, [profile]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setCompany = (e) => {
        e.preventDefault();
        dispatch({type: CHANGE_COMPANY, company: e.currentTarget.dataset.id});
        navigate('/');
    }
    return (
        <div id="choose-company" className="bg-lightblue">
            <form className="bg-white">
                <div className='title-semibold-24-32 text-center'>Выберите админ панель</div>
                <div className="companies">
                    {companies && companies.map((company, key) => (
                        <div key={key} className="company-btn" data-id={company} onClick={setCompany} ><img src={companyLogos[company]} /></div>
                    ))}
                </div>
            </form>
        </div>
    )
}

export default ChooseCompany;