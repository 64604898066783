import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from 'react-router-dom';
import { addUser, deleteUser, getRoles, getUsers } from '../../actions/user';
import './styles.scss';
import bcrypt from 'bcryptjs';
import { changePassword } from '../../actions/user';
import Modal from '../Modals/Modal';
import { CLEAR_USER_STATUSES } from '../../constants/actionTypes';
import LoadingModal from '../Modals/LoadingModal';

const dictionary={
    personalCabinet:{ru:'Личный кабинет', en:'Personal cabinet',kz:'Жеке кабинет'},
    pageSubtitle:{ru:'Сведения о вас и ваших настройках ',en:'Information about you and your settings',kz:'Сіз және сіздің параметрлеріңіз туралы ақпарат'},
    changePassword: {ru: 'Сменить пароль', en: 'Change password', kz: 'Кұпиясөзді өзгерту'},
    giveAccess: {ru:'Дать доступ другим пользователям', en: 'Give access to other users', kz: 'Басқа пайдаланушыларға рұқсат беру'},
    save: {ru:'Сохранить',en:'Save',kz:'Сақтау'},
    delete: {ru:'Удалить',en:'Delete',kz:'Жою'},
    wrongPass: {ru:'Неверный пароль',en:'Wrong password',kz:'Қате құпиясөз'},
    passNotMatches: {ru:'Пароли не совпадают',en:'Passwords do not match',kz:'Құпия сөздер сәйкес келмейді'},
    
}

const errorInitials = {
    oldPass: '',
    newPass: '',
    reNewPass: '',
};

const placeholders = {
    oldPass: {ru:'Введите ваш старые пароль', en: 'Enter your old password', kz: 'Ескі құпия сөзіңізді енгізіңіз'},
    newPass: {ru:'Введите новый пароль', en: 'Enter a new password', kz: 'Жаңа құпия сөзді енгізіңіз'},
    reNewPass: {ru:'Повторно введите новый пароль', en: 'Re-enter your new password', kz: 'Жаңа құпия сөзіңізді қайта енгізіңіз'},
    username: {ru:'Введите Имя сотрудника', en: 'Enter Employee Name', kz: 'Қызметкердің аты-жөнін енгізіңіз'},
    email: {ru:'Введите корпоративный email', en: 'Enter corporate email', kz: 'Корпоративтік электрондық поштаны енгізіңіз'},
    role: {ru: 'Выберите вид доступа',kz: 'Рұқсат түрін таңдаңыз', en: 'Choose the type of access'}
}

const Cabinet = () => {
    const {lang} = useSelector((state)=>state.global);
    const {users, passwordChanged, roles, delete_status, new_user_status} = useSelector((state)=>state.users);
    const user = JSON.parse(localStorage.getItem('profile')).user;
    const navigate = useNavigate();
    const [errors, setErrors] = useState(errorInitials);
    const dispatch = useDispatch();
    const body = document.querySelector('body');


    const [passFormData, setPassFormData] = useState({oldPass: '', newPass: '', reNewPass: '', valid: false});

    const [show, setShow] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [removeUserData, setRemoveUserData] = useState({id: '', name: ''});

    useEffect(()=>{
        if(!user){
            localStorage.clear();
            navigate('/login');
        }
        if(!users && user && user.role && user.role.name === 'superadmin')
            dispatch(getUsers());
        if(!roles || roles.length === 0)
            dispatch(getRoles());
    },[user,users, roles]);

    const openAccordion = (e) =>{
        e.preventDefault();
        const el = e.currentTarget.parentNode;
        el.classList.toggle('active');
    }

    useEffect(()=>{
        if(!show){
            setRemoveUserData({id: '', name: ''});
            setNewUserStatus(false)
        }
    }, [show])
    useEffect(()=>{
        if(delete_status){
            setActionLoading(false);
            dispatch({type: CLEAR_USER_STATUSES});
        }
        if(new_user_status){
            setShow(true);
            setActionLoading(false);
            dispatch({type: CLEAR_USER_STATUSES});
            if(new_user_status>=200 && new_user_status<300){
                setNewUserStatus('success');
            }else{
                setNewUserStatus('error');
            }
        }
    }, [delete_status, new_user_status])

    const removeUser = (e) =>{
        e.preventDefault();
        setActionLoading(true);
        setShow(false);
        dispatch(deleteUser(removeUserData.id));
    }
    const openRemoveModal = (e) =>{
        e.preventDefault();
        const id = e.currentTarget.dataset.id;
        const name = e.currentTarget.dataset.name;
        setRemoveUserData({id, name});
        setShow(true);
    }

    const [newUser, setNewUser] = useState({username: '', email: '', role: ''});
    const [newUserStatus, setNewUserStatus] = useState(null);

    const handleNewUserChange = (e) => {
        e.preventDefault();
        setNewUser({...newUser, [e.target.name]: e.target.value});
    }
    const handleNewUserSubmit = (e) =>{
        e.preventDefault();
        dispatch(addUser(newUser));
        setActionLoading(true);
    }

    const handlePassChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const name = e.target.name;
        setPassFormData({...passFormData, [name]: value});
    }

    const handlePassChangeSubmit = async (e) =>{
        e.preventDefault();
        const match = await bcrypt.compare(passFormData.oldPass, user.password);
        if(!match){
            setErrors({...errors, oldPass: 'wrongPass', reNewPass: ''});
        }else{
            if(passFormData.newPass !== passFormData.reNewPass)
                setErrors({...errors, oldPass: '', reNewPass: 'passNotMatches'});
            else{
                setErrors({...errors, oldPass: '', reNewPass: ''});
                dispatch( changePassword({oldPassword: passFormData.oldPass, newPassword: passFormData.newPass}));
            }
        }
    }

    useEffect(()=>{
        if(passFormData.oldPass.length>0 && passFormData.newPass.length>0 && passFormData.reNewPass.length>0){
            setPassFormData({...passFormData, valid: true});
        }else{
            setPassFormData({...passFormData, valid: false});
        }
    }, [passFormData.oldPass, passFormData.newPass, passFormData.reNewPass])

    return (
        <div id="cabinet">
            {actionLoading && (
                <LoadingModal />
            )}
            {removeUserData.id && (
                <Modal show={show} setShow={setShow} type="action" actionType='deleteUser' userName={removeUserData.name} action={removeUser} />
            )}
            {newUserStatus && (
                <Modal show={show} setShow={setShow} type="alert" alertStatus={newUserStatus} alertType='userAdded'/>
            )}
            <div className='blocks'>
                <div className='top'> 
                    <h1 className='semibold-32-48 color-black'>{dictionary.personalCabinet[lang]}</h1>
                    <div className='regular-16-16 color-darkgrey'>{dictionary.pageSubtitle[lang]}</div>
                </div>
                <div className='block'>
                    <div className='d-flex gap-3'>
                        <div>
                        </div>
                        <div className='d-flex gap-1 flex-column'>
                            <div className='semibold-24-32 color-black'>{user.username}</div>
                            <div className='regular-16-20 color-black'>{user.role.title[lang]}</div>
                        </div>
                    </div>
                </div>
                <div className='block accordion'>
                    <div className='accordion-header' onClick={openAccordion}>
                        {dictionary.changePassword[lang]}
                        <span></span>
                    </div>
                    <div className='accordion-body'>
                        <div className='row-1 row-lg-2'>
                            <div className='col'>
                                <div className='textfields-48px'>
                                    <label>Старый пароль</label>
                                    <input type="password" placeholder={placeholders.oldPass[lang]} name='oldPass' value={passFormData.oldPass} onChange={handlePassChange} />
                                    {errors.oldPass && (
                                        <div className='error'>{dictionary[errors.oldPass][lang]}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row-1 row-lg-2'>
                            <div className='col'>
                                <div className='textfields-48px'>
                                    <label>Новый пароль</label>
                                    <input type="password" placeholder={placeholders.newPass[lang]} name='newPass' value={passFormData.newPass} onChange={handlePassChange} />
                                    {errors.newPass && (
                                        <div className='error'>{dictionary[errors.newPass][lang]}</div>
                                    )}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='textfields-48px'>
                                    <label>Подтверждение нового пароля</label>
                                    <input type="password" placeholder={placeholders.reNewPass[lang]} name='reNewPass' value={passFormData.reNewPass} onChange={handlePassChange} />
                                    {errors.reNewPass && (
                                        <div className='error'>{dictionary[errors.reNewPass][lang]}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='accordion-footer'>
                            <button className='button-48' disabled={!passFormData.valid} onClick={handlePassChangeSubmit} >{dictionary.save[lang]}</button>
                        </div>
                    </div>
                </div>
                {(user && user.role && user.role.name==='superadmin') && (
                    <>
                        <div className='block accordion'>
                            <div className='accordion-header' onClick={openAccordion}>
                                {dictionary.giveAccess[lang]}
                                <span></span>
                            </div>
                            <div className='accordion-body'>
                                <div className='row-1 row-lg-2'>
                                    <div className='col'>
                                        <div className='textfields-48px'>
                                            <label>Имя Фамилия</label>
                                            <input type="text" name='username' placeholder={placeholders.username[lang]} value={newUser.username} onChange={handleNewUserChange}  />
                                            <div className='error'></div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='textfields-48px'>
                                            <label>Email</label>
                                            <input type="text" name="email" placeholder={placeholders.email[lang]} value={newUser.email} onChange={handleNewUserChange} />
                                            <div className={`error ${errors.reNewPass}`}></div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='textfields-48px'>
                                            <label>Вид доступа</label>
                                            <select name='role' onChange={handleNewUserChange} value={newUser.role}>
                                                <option value="">{placeholders.role[lang]}</option>
                                                {roles && roles.map((role, key)=> role.name !== 'superadmin' && (
                                                    <option key={key} value={role._id}>{role.title[lang]}</option>
                                                ))}
                                            </select>
                                            {/* <input type="text" name="email" placeholder={placeholders.email[lang]} value={newUser.email} onChange={handleNewUserChange} /> */}
                                            <div className={`error ${errors.reNewPass}`}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-footer'>
                                    <button className='button-48' onClick={handleNewUserSubmit}>{dictionary.save[lang]}</button>
                                </div>
                            </div>
                        </div>
                        <div className='table-block'>
                            <div className='table-title'></div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Имя</th>
                                        <th>Электронная почта</th>
                                        <th>Доступ</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(users && users.length>0) && (
                                        users.map((usr,key)=>(
                                        <tr key={key}>
                                            <td>{key+1}</td>
                                            <td>{usr.username}</td>
                                            <td>{usr.email}</td>
                                            <td>{usr.role.title[lang]}</td>
                                            <td>
                                                {user._id !== usr._id && (
                                                    <a data-id={usr._id} data-name={usr.username} onClick={openRemoveModal}>
                                                        {dictionary.delete[lang]}
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    )))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
};

export default Cabinet;