import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import './styles.scss';


const LoadingModal = () => {
    const body = document.querySelector('body');

    return (
        <div className='modal'>
            <div className='modal-body modal-loading'>
                <div className="lds-ring">
                </div>
            </div>
        </div>
    );
};

export default LoadingModal;