import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPageByName, updateItems } from '../../../actions/page';
import { getLangs, toggleLangs } from '../../../actions/user';
import { CLEAR_LANG_STATUS, CLEAR_PAGE_STATUSES } from '../../../constants/actionTypes';
import Loading from '../../Loading/Loading';
import LoadingModal from '../../Modals/LoadingModal';
import Modal from '../../Modals/Modal';

import '../../subcomponents/Block/styles.scss';
import Edit from '../../subcomponents/Edit/Edit';

const placeholders = {
    whatsapp: {ru: '', kz: '', en: ''},
    instagram: {ru: '', kz: '', en: ''},
    facebook: {ru: '', kz: '', en: ''},
    linkedin: {ru: '', kz: '', en: ''},
}

const index = 'contacts'

const Contacts = () => {
    const {page} = useSelector((state)=>state.pages);
    const {lang, langs, langChangeStatus} = useSelector((state)=>state.global);
    const [initItems, setInitItems] = useState([]);
    const [tmpItems, setTmpItems] = useState([]);
    const [changed, setChanged] = useState(false);
    const {newItems, itemsKey, itemsStatus} = useSelector((state)=>state.pages);
    const [btnLoading, setBtnLoading] = useState(false);
    const [formDataLangs, setFormDataLangs] = useState({en: false, ru: false, kz: false});
    
    const dispatch = useDispatch();
    

    useEffect(()=>{
        if(!page){
            dispatch(getPageByName('contacts'));
        }else{
            setInitItems([
                {type: "singleText",singleText:page.phone_number.all, _id:page.phone_number._id},
                {type: "singleText",singleText:page.email.all, _id:page.email._id},
                {type: "singleText",singleText:page.whatsapp.all, _id:page.whatsapp._id},
                {type: "singleText",singleText:page.instagram.all, _id:page.instagram._id},
                {type: "singleText",singleText:page.facebook.all, _id:page.facebook._id},
                {type: "singleText",singleText:page.linkedin.all, _id:page.linkedin._id},
            ]);
            setTmpItems([
                {type: "singleText",singleText:page.phone_number.all, _id:page.phone_number._id},
                {type: "singleText",singleText:page.email.all, _id:page.email._id},
                {type: "singleText",singleText:page.whatsapp.all, _id:page.whatsapp._id},
                {type: "singleText",singleText:page.instagram.all, _id:page.instagram._id},
                {type: "singleText",singleText:page.facebook.all, _id:page.facebook._id},
                {type: "singleText",singleText:page.linkedin.all, _id:page.linkedin._id},
            ]);
        }
    },[dispatch, page]);

    useEffect(()=>{
        if(!langs)
            dispatch(getLangs());
        else{
            let newFormDataLang = {en: false, ru: false, kz: false};
            langs.forEach(lg => {
                newFormDataLang[lg.name] = lg.isOn;
            });
            setFormDataLangs(newFormDataLang);
        }
    }, [langs])


    const handleLangChange = (e) =>{
        e.preventDefault();
        const name = e.currentTarget.dataset.name;
        setFormDataLangs({...formDataLangs, [name]: !formDataLangs[name]});
        setChanged(true)
    }

    const handleChange = (type, index, change, fieldname) => {
        switch (type) {     
            case "singleText":
                setTmpItems(existingItems => {
                    return existingItems.map((item, j) => {
                        return j === index ? {...item, singleText: change, fieldname} : item
                    })
                })
                setChanged(true);
                break;        
            default:
                break;
        }
    }   
    const saveChanges = (e) => {
        setBtnLoading(true);
        dispatch(toggleLangs(formDataLangs));
        dispatch(updateItems(tmpItems, index));
    }
    const cancelChanges = (e) => {
        if(langs){
            let newFormDataLang = {en: false, ru: false, kz: false};
            langs.forEach(lg => {
                newFormDataLang[lg.name] = lg.isOn;
            });
            setFormDataLangs(newFormDataLang);
        }
        setTmpItems(initItems);
        setChanged(false);
    }

    const [show, setShow] = useState(false);
    const [modalStatus, setModalStatus] = useState('');

    useEffect(()=>{
        if(itemsStatus && langChangeStatus && itemsKey===index){
            setBtnLoading(false);
            setChanged(false);
            setShow(true);
            if(newItems){
                setInitItems(newItems);
                setTmpItems(newItems);
            }
            if((langChangeStatus >= 200 && langChangeStatus < 300) && (itemsStatus >= 200 && itemsStatus < 300))
                setModalStatus('success');
            else
                setModalStatus('error');

        }
    },[itemsStatus, itemsKey, langChangeStatus]);
    return tmpItems.length===0 ? (
        <Loading />
    ):(
        <div id="contacs">
            <div className='block'>
                {btnLoading && (
                    <LoadingModal />
                )}
                <Modal type='alert' alertStatus={modalStatus} alertType='saved' show={show} setShow={setShow} />
                <div className="block-title">Контакты</div>
                <div className="block-content">
                    <div className='row-1 row-lg-2'>
                        <div className='col'>
                            <div className='subblock'> 
                                <div className="block-subtitle">Контактый номер</div>
                                <Edit index={0} type="singleText" singleText={tmpItems[0].singleText} handleChange={handleChange} label="Номер телефона"/>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='subblock'> 
                                <div className="block-subtitle">Почта</div>
                                <Edit index={1} type="singleText" singleText={tmpItems[1].singleText} handleChange={handleChange} label="Ссылка на почту"/>
                            </div>
                        </div>
                    </div>
                <div className='subblock'> 
                    <div className="block-subtitle">Социальные сети</div>
                    <div className='row-1 row-lg-2'>
                        <div className='col mb-3'>
                            <Edit index={2} type="singleText" groupText="https://www.wa.me/" placeholder={placeholders.whatsapp[lang]} singleText={tmpItems[2].singleText} handleChange={handleChange} label="WhatsApp"/>
                        </div>
                        <div className='col mb-3'>
                            <Edit index={3} type="singleText" groupText="https://www.instagram.com/" placeholder={placeholders.instagram[lang]} singleText={tmpItems[3].singleText} handleChange={handleChange} label="Instagram"/>
                        </div>
                        <div className='col'>
                            <Edit index={4} type="singleText" groupText="https://www.facebook.com/" placeholder={placeholders.facebook[lang]} singleText={tmpItems[4].singleText} handleChange={handleChange} label="Facebook"/>
                        </div>
                        <div className='col'>
                            <Edit index={5} type="singleText" groupText="https://www.linkedin.com/in/" placeholder={placeholders.linkedin[lang]} singleText={tmpItems[5].singleText} handleChange={handleChange} label="Linkedin"/>
                        </div>
                    </div>
                </div>
                <div className='subblock'> 
                    <div className="block-subtitle">Языки</div>
                    <div className='row-2 row-lg-4'>
                        <div className='col'>
                            <div className='switch'>
                                <div className='label'>Английский язык</div>
                                <label onClick={handleLangChange} data-name='en'>
                                    <input type="checkbox" checked={formDataLangs.en} onChange={(e)=>{}}/>
                                    <span className='switch-slider'></span>
                                </label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='switch'>
                                <div className='label'>Русский язык</div>
                                <label onClick={handleLangChange} data-name='ru'>
                                    <input type="checkbox" checked={formDataLangs.ru} onChange={(e)=>{}}/>
                                    <span className='switch-slider'></span>
                                </label>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='switch'>
                                <div className='label'>Казахский язык</div>
                                <label onClick={handleLangChange} data-name='kz'>
                                    <input type="checkbox" checked={formDataLangs.kz} onChange={(e)=>{}}/>
                                    <span className='switch-slider'></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-footer">
                    <button className={`button-48 outline `} disabled={!changed} onClick={cancelChanges} >Сбросить изменения</button>
                    <button className={`button-48 `} disabled={!changed && !btnLoading} onClick={saveChanges}>
                        Сохранить
                    </button>
                </div>
            </div>
            </div>
        </div>  
    );
};

export default Contacts;